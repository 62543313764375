import { CheckCircle, Close } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { USER_DOCUMENT_STATUS } from './MydraUserScoringDocumentsTable.constants';

export function UserDocumentStatusChip({ status, showIcon }) {
    const color = {
        [USER_DOCUMENT_STATUS.Created]: 'primary',
        [USER_DOCUMENT_STATUS.Extracting]: 'warning',
        [USER_DOCUMENT_STATUS.ExtractionOk]: 'success',
        [USER_DOCUMENT_STATUS.ExtractionFailed]: 'error',
        [USER_DOCUMENT_STATUS.ReviewRequired]: 'warning',
        [USER_DOCUMENT_STATUS.Rejected]: 'error',
        [USER_DOCUMENT_STATUS.Validated]: 'success',
    }[status];

    const icon = {
        [USER_DOCUMENT_STATUS.Validated]: <CheckCircle />,
        [USER_DOCUMENT_STATUS.Rejected]: <Close />,
    }[status];

    return (
        <Chip
            label={status}
            color={color ?? 'default'}
            size="small"
            style={{ margin: '2px', textTransform: 'uppercase' }}
            icon={showIcon ? icon : null}
            sx={{
                '& .MuiChip-icon': {
                    order: 1,
                    marginRight: '4px',
                    marginLeft: 0,
                },
            }}
        />
    );
}
