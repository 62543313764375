import React,  { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { 
    Container, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    Typography, 
    Avatar, 
    Chip,
    Tooltip, 
    Button,
    CircularProgress, 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Link,
    Stack,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormLabel,
    Pagination,
    TablePagination,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/Add';

const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        // If direct parsing fails, try removing the 'Z' if present
        const cleanDate = dateString.replace('Z', '');
        const newDate = new Date(cleanDate);
        if (isNaN(newDate.getTime())) {
            return 'Invalid Date';
        }
        return newDate.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    }
    return date.toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    });
};

const TableHeader = ( { onSort, sortAscending }) => (
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
      <TableRow>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>#</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Logo</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
        <TableCell sx={{ 
          color: 'white', 
          fontWeight: 'bold', 
          cursor: 'pointer',
          '&:hover': { color: 'grey.300' }
        }} onClick={onSort}>
          # of courses
          {sortAscending ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
        </TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Country</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Has Agreement</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Is Affiliate</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Created At</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Updated At</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );

  // const MydraProvidersList = ({ providers, onEditProvider, isLoading, authToken, userEmail, environment, onDeleteSuccess, onDeleteError }) => {
  const MydraProvidersList = ({
      providers, 
      onEditProvider, 
      isLoading, 
      authToken, 
      userEmail, 
      environment, 
      onDeleteSuccess, 
      onDeleteError, 
      setProviders,
      pagination = { 
          page: 1, 
          limit: 10, 
          totalPages: 1,
          rowsPerPageOptions: [10, 20, 50, 100]
      },
      onPageChange,
      onRowsPerPageChange
  }) => {
    const navigate = useNavigate();
    const [sortAscending, setSortAscending] = useState(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [providerToDelete, setProviderToDelete] = useState(null);
    const [expandedRows, setExpandedRows] = useState(new Set());
    const [loadingLandingPages, setLoadingLandingPages] = useState({});
    const [providerLandingPages, setProviderLandingPages] = useState({});
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [isCreatingPage, setIsCreatingPage] = useState(false);
    const [pollingIntervals, setPollingIntervals] = useState({});
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [websiteUrl, setWebsiteUrl] = useState('');
    const [financialTerms, setFinancialTerms] = useState({
        fix_pay: true,
        tax_benefit: true,
        learning_budget: true,
        fundae: false,
        isa: false
    });
    const [pollingCountdown, setPollingCountdown] = useState(20); // 20 second countdown
    const POLLING_INTERVAL = 20000; // 20 seconds between checks
    const [isUpdate, setIsUpdate] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const REACT_APP_API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const REACT_APP_COURSE_MATCH_AI_API_BASE_URL = process.env.REACT_APP_COURSE_MATCH_AI_API_BASE_URL;

    const fetchLandingPagesStatus = async (providerId) => {
        console.log(`Fetching landing pages for provider ID: ${providerId}`);
        
        try {
            const response = await fetch(`${REACT_APP_API_BASE_URL}/api/education-providers/${providerId}/landing-pages/check`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': environment ? 'Production' : 'Staging',
                },
            });
            
            const data = await response.json();
            
            if (data.landing_pages && data.landing_pages.length > 0) {
                console.log(`Found ${data.landing_pages.length} landing pages for provider ${providerId}`);
                // Clear the polling interval
                clearInterval(pollingIntervals[providerId]);
                setPollingIntervals(prev => ({ ...prev, [providerId]: null }));
                // Clear the loading state and isCreatingPage flag
                setLoadingLandingPages(prev => ({ ...prev, [providerId]: false }));
                setIsCreatingPage(false);
                setPollingCountdown(30);
            } else {
                setPollingCountdown(30); // Reset countdown after each check
            }

            setProviderLandingPages(prev => ({
                ...prev,
                [providerId]: data
            }));
        } catch (error) {
            console.error('Error fetching landing pages:', error);
            // Also clear loading state on error
            setLoadingLandingPages(prev => ({ ...prev, [providerId]: false }));
            setIsCreatingPage(false);
        }
    };

    const handleSort = () => {
      setSortAscending(!sortAscending);
    };

    const sortedProviders = [...providers].sort((a, b) => {
      if (sortAscending) {
          return a.totalCourses - b.totalCourses;
      } else {
          return b.totalCourses - a.totalCourses;
      }
  });

  const handleConfirmOpen = (provider) => {
    setProviderToDelete(provider);
    setOpenConfirmDialog(true);
  };
  
  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };
  
  const handleDeleteProvider = async () => {
    if (providerToDelete) {
      deleteProvider(providerToDelete._id);
      handleConfirmClose();
    }
  };

  const deleteProvider = async (providerId) => {
    try {
        // print authtoken
        console.log('authToken:', authToken );

        const response = await fetch(`${REACT_APP_API_BASE_URL}/mydra/education-providers/${providerId}`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'X-User-Email': userEmail,
              'X-Environment': environment ? 'Production' : 'Staging',
            },
        });
        if (response.status === 204 || response.status === 200) {
          onDeleteSuccess(`Provider deleted successfully.`);
          // Remove the deleted provider from the list
          const updatedProviders = providers.filter(provider => provider._id !== providerId);
          setProviders(updatedProviders);
        } else {
          throw new Error('Failed to delete provider');
        }
    } catch (error) {
        console.error('Error deleting provider:', error);
        onDeleteError('Failed to delete provider');
    }
  };

    const handleExpandRow = async (providerId) => {
        if (!expandedRows.has(providerId)) {
            setLoadingLandingPages(prev => ({ ...prev, [providerId]: true }));
            await fetchLandingPagesStatus(providerId);
            setLoadingLandingPages(prev => ({ ...prev, [providerId]: false }));
        }
        setExpandedRows(prev => {
            const next = new Set(prev);
            if (next.has(providerId)) {
                next.delete(providerId);
            } else {
                next.add(providerId);
            }
            return next;
        });
    };

    const getMarketplaceUrl = () => {
        return environment ? 'https://marketplace.mydra.io' : 'https://marketplace.staging.mydra.io';
    };

    const handleCreateLandingPage = (provider, isUpdateMode = false) => {
        console.log('Creating landing page for provider:', provider.name);
        console.log('Environment:', environment ? 'Production' : 'Staging');
        
        setSelectedProvider({
            name: provider.name,
            id: provider._id,
            slug: provider.slug
        });
        setIsUpdate(isUpdateMode);
        setOpenCreateDialog(true);
    };

    const handleFinancialTermChange = (event) => {
        setFinancialTerms({
            ...financialTerms,
            [event.target.name]: event.target.checked
        });
    };

    const handleSubmitCreate = async () => {
        setIsCreatingPage(true);
        setOpenCreateDialog(false);
        
        try {
            // Log what we're sending to the backend
            console.log('Submitting landing page creation/update with:', {
                provider_id: selectedProvider.id,
                linkedin_url: linkedinUrl,
                website_url: websiteUrl,
                environment: environment ? 'Production' : 'Staging',
                financial_terms: Object.entries(financialTerms)
                    .filter(([_, checked]) => checked)
                    .map(([term]) => term),
                action_type: isUpdate ? "update" : "create"
            });

            const response = await fetch(`${REACT_APP_COURSE_MATCH_AI_API_BASE_URL}/education-provider/start-analysis`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    provider_id: selectedProvider.id,
                    linkedin_url: linkedinUrl,
                    website_url: websiteUrl,
                    environment: environment ? 'Production' : 'Staging',
                    financial_terms: Object.entries(financialTerms)
                        .filter(([_, checked]) => checked)
                        .map(([term]) => term),
                    action_type: isUpdate ? "update" : "create"
                })
            });

            if (!response.ok) {
                throw new Error('Failed to create landing page');
            }

            // Show success message to user
            setSnackbarMessage(
                isUpdate 
                    ? "Update triggered successfully. Please refresh the page in a few minutes to see the changes."
                    : "Creation started successfully. Please refresh the page in a few minutes to see the changes."
            );
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            console.log(`Started ${isUpdate ? 'update' : 'creation'} process for provider ID: ${selectedProvider.id}`);
            console.log('Response from backend:', await response.json());

            // Show loading state in UI
            setLoadingLandingPages(prev => ({ ...prev, [selectedProvider.id]: true }));

            // Start polling
            const interval = setInterval(() => {
                console.log(`Checking landing pages for provider ID: ${selectedProvider.id}`);
                fetchLandingPagesStatus(selectedProvider.id);
            }, POLLING_INTERVAL);

            setPollingIntervals(prev => ({ ...prev, [selectedProvider.id]: interval }));

        } catch (error) {
            console.error('Error creating/updating landing page:', error);
            setIsCreatingPage(false);
            
            // Show error message to user
            setSnackbarMessage(`Failed to ${isUpdate ? 'update' : 'create'} landing page: ${error.message}`);
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    // Clean up polling when component unmounts or when pages are found
    useEffect(() => {
        return () => {
            Object.values(pollingIntervals).forEach(interval => clearInterval(interval));
        };
    }, [pollingIntervals]);

    // Add countdown effect
    useEffect(() => {
        const timer = setInterval(() => {
            setPollingCountdown((prev) => (prev > 0 ? prev - 1 : 30));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    if (isLoading) {
        return (
          <Container maxWidth="lg" sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Container>
        );
    }

    if (!providers || providers.length === 0) {
        return (
          <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="body1">No providers available</Typography>
          </Container>
        );
    }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table aria-label="provider table">
          <TableHeader onSort={handleSort} sortAscending={sortAscending} />
            <TableBody>
                {sortedProviders.map((provider, index) => [
                    <TableRow 
                        key={provider._id}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.2s ease-in-out',
                            },
                        }}
                    >
                        <TableCell>
                            {(pagination.page - 1) * pagination.limit + index + 1}
                        </TableCell>
                        <TableCell>
                        <Avatar alt={provider.name} src={provider.logoUrl} />
                        </TableCell>
                        <TableCell>
                        <Typography variant="subtitle1" component="div">
                            {provider.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {provider.slug}
                        </Typography>
                        </TableCell>
                        <TableCell>{provider.totalCourses}</TableCell>
                        <TableCell>{provider.country}</TableCell>
                        <TableCell>
                        <Chip
                            icon={provider.hasAgreement ? <CheckCircleIcon /> : <CancelIcon />}
                            label={provider.hasAgreement ? 'Yes' : 'No'}
                            color={provider.hasAgreement ? 'success' : 'default'}
                        />
                        </TableCell>
                        <TableCell>
                        <Chip
                            icon={provider.isAffiliate ? <CheckCircleIcon /> : <CancelIcon />}
                            label={provider.isAffiliate ? 'Yes' : 'No'}
                            color={provider.isAffiliate ? 'success' : 'default'}
                        />
                        </TableCell>
                        <TableCell>{formatDate(provider.createdAt)}</TableCell>
                        <TableCell>{formatDate(provider.updatedAt)}</TableCell>
                        <TableCell>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent row click
                                onEditProvider(provider);
                              }}
                              size="small"
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent row click
                              handleConfirmOpen(provider);
                            }}
                            size="small"
                          >
                            <DeleteIcon style={{ color: 'red' }} />
                          </IconButton>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Stack direction="row" spacing={1}>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={<SchoolIcon />}
                                    onClick={() => navigate(`/mydra/provider/${provider._id}/${provider.name}`)}
                                >
                                    View Courses
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    startIcon={loadingLandingPages[provider._id] ? (
                                        <CircularProgress size={16} />
                                    ) : (
                                        <DescriptionIcon />
                                    )}
                                    onClick={() => handleExpandRow(provider._id)}
                                    endIcon={expandedRows.has(provider._id) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                >
                                    Landing Pages
                                </Button>
                            </Stack>
                        </TableCell>
                    </TableRow>,
                    expandedRows.has(provider._id) && (
                        <TableRow 
                            key={`expanded-${provider._id}`}
                            sx={{
                                backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <TableCell colSpan={11}>
                                <Box sx={{ p: 2 }}>
                                    {loadingLandingPages[provider._id] ? (
                                        <Box display="flex" flexDirection="column" alignItems="center" p={2}>
                                            <CircularProgress size={24} sx={{ mb: 1 }} />
                                            <Typography variant="body2" color="text.secondary">
                                                {isUpdate 
                                                    ? "Update request triggered. Please refresh the page in a few minutes to see the changes."
                                                    : "Creating Landing Pages... This process can take several minutes."
                                                }
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                Checking again in {pollingCountdown} seconds. You can wait or come back later.
                                            </Typography>
                                        </Box>
                                    ) : providerLandingPages[provider._id]?.landing_pages?.length > 0 ? (
                                        <Box sx={{ p: 2 }}>
                                            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={<EditIcon />}
                                                    onClick={() => handleCreateLandingPage(provider, true)}
                                                >
                                                    Update Landing Page
                                                </Button>
                                            </Stack>
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Language</TableCell>
                                                            <TableCell>Title</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell>Created At</TableCell>
                                                            <TableCell>Last Updated</TableCell>
                                                            <TableCell>Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {providerLandingPages[provider._id]?.landing_pages?.map((page) => (
                                                            <TableRow key={page.id || page._id || `${provider._id}-${page.url}`}>
                                                                <TableCell>
                                                                    <Chip 
                                                                        label={page.language.toUpperCase()} 
                                                                        size="small"
                                                                        color="primary"
                                                                        variant="outlined"
                                                                    />
                                                                </TableCell>
                                                                <TableCell>{page.title}</TableCell>
                                                                <TableCell>
                                                                    <Chip 
                                                                        label={page.isPublished ? "Published" : "Draft"}
                                                                        color={page.isPublished ? "success" : "default"}
                                                                        size="small"
                                                                    />
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatDate(page.created_at)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {formatDate(page.updated_at)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Stack direction="row" spacing={1}>
                                                                        <Button
                                                                            variant="outlined"
                                                                            size="small"
                                                                            onClick={() => window.open(`${getMarketplaceUrl()}/landing-pages/${page.id}`, '_blank')}
                                                                        >
                                                                            Preview
                                                                        </Button>
                                                                        {page.isPublished && (
                                                                            <Button
                                                                                variant="contained"
                                                                                size="small"
                                                                                onClick={() => window.open(`${getMarketplaceUrl()}/partners/${page.slug}`, '_blank')}
                                                                            >
                                                                                Live
                                                                            </Button>
                                                                        )}
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    ) : (
                                        <Box sx={{ textAlign: 'center' }}>
                                            {isCreatingPage ? (
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                                                  <Box>
                                                    <CircularProgress size={20} sx={{ mr: 1 }} />
                                                    <Typography>Creating Landing Pages...</Typography>
                                                  </Box>  
                                                  <Box>
                                                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                                                      Checking again in {pollingCountdown} seconds. You can wait or come back later.
                                                    </Typography>
                                                  </Box>
                                                </Box>
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => handleCreateLandingPage(provider)}
                                                >
                                                    Create Landing Pages
                                                </Button>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>
                        </TableRow>
                    )
                ].filter(Boolean))}
            </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <TablePagination
            component="div"
            count={pagination.totalRecords || pagination.totalPages * pagination.limit}
            page={pagination.page - 1}
            rowsPerPage={pagination.limit}
            rowsPerPageOptions={pagination.rowsPerPageOptions}
            onPageChange={(event, newPage) => onPageChange(event, newPage + 1)}
            onRowsPerPageChange={onRowsPerPageChange}
            labelRowsPerPage="Providers per page:"
        />
      </Box>
      <Dialog
        open={openConfirmDialog}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this provider? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProvider} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openCreateDialog} onClose={() => setOpenCreateDialog(false)}>
        <DialogTitle>{isUpdate ? 'Update Landing Pages' : 'Create Landing Pages'}</DialogTitle>
        <DialogContent>
            <TextField
                margin="dense"
                label="Provider Name"
                value={selectedProvider?.name || ''}
                fullWidth
                disabled
                sx={{ mb: 2 }}
            />
            <TextField
                margin="dense"
                label="Provider ID"
                value={selectedProvider?.id || ''}
                fullWidth
                disabled
                sx={{ mb: 2 }}
            />
            <TextField
                margin="dense"
                label="Environment"
                value={environment ? 'Production' : 'Staging'}
                fullWidth
                disabled
                sx={{ mb: 2 }}
            />
            <TextField
                autoFocus
                margin="dense"
                label="LinkedIn URL"
                type="url"
                fullWidth
                value={linkedinUrl}
                onChange={(e) => setLinkedinUrl(e.target.value)}
                required
                sx={{ mb: 2 }}
            />
            <TextField
                margin="dense"
                label="Website URL"
                type="url"
                fullWidth
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                sx={{ mb: 3 }}
            />
            
            <FormLabel component="legend" sx={{ mb: 1 }}>Financial Terms</FormLabel>
            <FormGroup>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={financialTerms.fix_pay}
                            onChange={handleFinancialTermChange}
                            name="fix_pay"
                        />
                    }
                    label="Fixed Payment"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={financialTerms.tax_benefit}
                            onChange={handleFinancialTermChange}
                            name="tax_benefit"
                        />
                    }
                    label="Tax Benefit"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={financialTerms.learning_budget}
                            onChange={handleFinancialTermChange}
                            name="learning_budget"
                        />
                    }
                    label="Learning Budget"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={financialTerms.fundae}
                            onChange={handleFinancialTermChange}
                            name="fundae"
                        />
                    }
                    label="FUNDAE"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={financialTerms.isa}
                            onChange={handleFinancialTermChange}
                            name="isa"
                        />
                    }
                    label="Income Share Agreement (ISA)"
                />
            </FormGroup>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenCreateDialog(false)}>Cancel</Button>
            <Button onClick={handleSubmitCreate} variant="contained">
                {isUpdate ? 'Update' : 'Create'}
            </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MydraProvidersList;
