import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    TableContainer,
    Switch,
    Box,
    CircularProgress,
} from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { format, formatDistanceToNow } from 'date-fns';
import ArticleIcon from '@mui/icons-material/Article';


const MydraNewCoursesPage = () => {
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isProduction, setIsProduction] = useState(true);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null); // Assuming you want to store the user's email as well
    const navigate = useNavigate();

    const isInitialLoad = useRef(true); // Correct placement of useRef

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Add sorting state
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');

    // Add sorting function
    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    // Add sorting logic to courses
    const sortedCourses = useMemo(() => {
        if (!sortField) return courses;

        return [...courses].sort((a, b) => {
            if (sortField === 'agreement') {
                const aHasAgreement = a.agreements && a.agreements.length > 0;
                const bHasAgreement = b.agreements && b.agreements.length > 0;
                
                if (aHasAgreement === bHasAgreement) {
                    if (!aHasAgreement) return 0;
                    return sortDirection === 'asc' 
                        ? a.agreements[0].name.localeCompare(b.agreements[0].name)
                        : b.agreements[0].name.localeCompare(a.agreements[0].name);
                }
                return sortDirection === 'asc' 
                    ? (aHasAgreement ? -1 : 1)
                    : (aHasAgreement ? 1 : -1);
            }
            
            if (sortField === 'created') {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return sortDirection === 'asc' 
                    ? dateA - dateB 
                    : dateB - dateA;
            }
            
            return 0;
        });
    }, [courses, sortField, sortDirection]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    console.log('User logged in:', user.email);
                    setAuthToken(token);
                });
            } else {
                console.log('User not logged in');
                // Optionally redirect to login page here
            }
        });
    }, []);

    useEffect(() => {

        setIsLoading(true);

        const fetchCourses = async () => {
            if (authToken) {  // Ensure the token is available before fetching
                const formattedStartDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]; // 7 days ago
                // const formattedEndDate = new Date().toISOString().split('T')[0]; // today
                // Create a new Date object for "today" and add one day to it
                const tomorrow = new Date();
                tomorrow.setDate(tomorrow.getDate() + 1); // Adds one day
                const formattedEndDate = tomorrow.toISOString().split('T')[0]; // tomorrow

                try {
                    const url = `${API_BASE_URL}/mydra/courses-by-date?createdAtStart=${formattedStartDate}&createdAtEnd=${formattedEndDate}&env=${isProduction ? 'Production' : 'Staging'}`;
                    const response = await fetch(url, {
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                            'X-User-Email': userEmail, // Use the email parameter
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                        }
                    });
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    const data = await response.json();
                    const sortedCourses = [...data].sort((a, b) => 
                        new Date(b.createdAt) - new Date(a.createdAt)
                    );
                    setCourses(sortedCourses);
                    console.log('Courses:', data);
                } catch (error) {
                    console.error('Failed to fetch courses:', error);
                } finally {
                    setIsLoading(false);
                }
            }
        };

        fetchCourses();
        const interval = setInterval(fetchCourses, 30000); // Fetch every 30 seconds
        return () => clearInterval(interval); // Clear interval on component unmount

    }, [authToken, isProduction, userEmail]);

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleRowClick = (courseId) => {
        // Pass the environment as a query parameter if it is not Production
        // navigate(`/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`);
        const url = `/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`;
        console.log('Navigating to:', url);
        window.open(url, '_blank'); // '_blank' opens the URL in a new tab

    };

    const tableRowStyle = {
        cursor: 'pointer', // Change cursor to pointer on hover
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)', // Adds a light grey background on hover
        }
    };

    const formatDateCohorts = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
      };

      const headerCellStyle = {
        fontWeight: 700,
        color: '#1a1a1a',
        fontSize: '0.875rem',
        backgroundColor: '#f5f5f5',
        borderBottom: '2px solid #e0e0e0',
        padding: '16px 8px',
        whiteSpace: 'nowrap',
        position: 'sticky',
        top: 0,
        zIndex: 1,
        '&::after': {
            content: '""',
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderBottom: '2px solid #e0e0e0'
        }
    };

    // Create a reusable style for sortable headers
    const sortableHeaderStyle = {
        ...headerCellStyle,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.08)',
        }
    };

    return (
        <Container 
            maxWidth={false} 
            sx={{ 
                mt: 4,
                mx: 2 // Add some margin on the sides
            }}
        >
            <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                  }}
            >
                    Recently Created Courses
            </Typography>
            
            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer 
                    component={Paper}
                    sx={{
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden',
                        // maxHeight: '80vh', // This enables scrolling
                        '& .MuiTable-root': {
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                        },
                    }}
                    >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={headerCellStyle}>#</TableCell>
                                <TableCell sx={headerCellStyle}>Provider</TableCell>
                                <TableCell sx={headerCellStyle}>Course Name</TableCell>
                                <TableCell sx={headerCellStyle}>Cost</TableCell>
                                <TableCell sx={headerCellStyle}>Next Cohort</TableCell>
                                <TableCell sx={headerCellStyle}>Category</TableCell>
                                <TableCell sx={headerCellStyle}>Course Id</TableCell>
                                <TableCell 
                                    sx={sortableHeaderStyle}
                                    onClick={() => handleSort('created')}
                                >
                                    <Box display="flex" alignItems="center">
                                        Created
                                        {sortField === 'created' && (
                                            <Typography variant="caption" sx={{ ml: 1 }}>
                                                {sortDirection === 'asc' ? '↑' : '↓'}
                                            </Typography>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={headerCellStyle}>Published</TableCell>
                                <TableCell 
                                    sx={sortableHeaderStyle}
                                    onClick={() => handleSort('agreement')}
                                >
                                    <Box display="flex" alignItems="center">
                                        Agreement
                                        {sortField === 'agreement' && (
                                            <Typography variant="caption" sx={{ ml: 1 }}>
                                                {sortDirection === 'asc' ? '↑' : '↓'}
                                            </Typography>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={headerCellStyle}>Validation</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedCourses.map((course, index) => (
                                <TableRow key={course._id} onClick={() => handleRowClick(course._id)} sx={tableRowStyle}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {course.educationProvider.logoUrl ? (
                                            <img
                                                src={course.educationProvider.logoUrl}
                                                alt="Provider Logo"
                                                style={{ width: 40, height: 40, marginRight: 8 }}
                                            />
                                            ) : (
                                            <Box width={40} height={40} bgcolor="grey.300" marginRight={1} display="flex" alignItems="center" justifyContent="center">
                                                <Typography variant="caption">No Logo</Typography>
                                            </Box>
                                            )}
                                            <Typography variant="body2">{course.educationProvider.name}</Typography>
                                        </Box>
                                    </TableCell>
                                    <TableCell>{course.name}</TableCell>
                                    <TableCell>
                                    {course.cohorts && course.cohorts[0]?.cost
                                        ? `${(course.cohorts[0].cost.amount / 100).toFixed(2)} ${course.cohorts[0].cost.currency}`
                                        : 'N/A'}
                                    </TableCell>
                                    <TableCell>
                                        {course.cohorts && course.cohorts[0]?.startDate && course.cohorts[0]?.endDate
                                            ? `${formatDateCohorts(course.cohorts[0].startDate)} - ${formatDateCohorts(course.cohorts[0].endDate)}`
                                            : 'Self-paced'}
                                    </TableCell>
                                    <TableCell>{course.categories.map(category => category.name).join(', ')}</TableCell>
                                    <TableCell>{course._id}</TableCell>
                                    <TableCell>
                                        {formatDistanceToNow(new Date(course.createdAt), { addSuffix: true })}
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Typography variant="body2" component="span">
                                                {course.published ? "Yes" : "No"}
                                            </Typography>
                                            <FiberManualRecordIcon
                                                sx={{ color: course.published ? 'green' : 'red', ml: 1 }}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            {course.agreements && course.agreements.length > 0 ? (
                                                <Box display="flex" alignItems="center">
                                                    <ArticleIcon sx={{ fontSize: 16, mr: 1, color: 'primary.main' }} />
                                                    <Typography variant="body2">
                                                        {course.agreements[0].name}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Typography 
                                                    variant="body2" 
                                                    sx={{ 
                                                        color: 'error.main',
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        gap: 0.5
                                                    }}
                                                >
                                                    ⚠️ No agreement
                                                </Typography>
                                            )}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {course.validationType ? (
                                            <Typography 
                                                variant="caption" 
                                                sx={{ 
                                                    backgroundColor: 'primary.light',
                                                    color: 'primary.contrastText',
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: 1,
                                                    display: 'inline-block'
                                                }}
                                            >
                                                {course.validationType}
                                            </Typography>
                                        ) : (
                                            <Typography variant="body2" color="text.secondary">
                                                None
                                            </Typography>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default MydraNewCoursesPage;

