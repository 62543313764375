import { useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useEnvironment } from '../contexts/EnvironmentContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const useFetch = () => {
    const { environment } = useEnvironment();
    const { authToken } = useAuth();

    const fetch = useCallback(
        (path, init) =>
            window
                .fetch(`${API_BASE_URL}${path}`, {
                    ...init,
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken}`,
                        'X-Environment': environment,
                        ...init?.headers,
                    },
                })
                .then(async (res) => {
                    if (!res.ok) {
                        try {
                            const error = await res.json();
                            throw new Error(error);
                        } catch (e) {
                            throw new Error(res.statusText);
                        }
                    }

                    if (init?.asRawResponse) {
                        return res;
                    }

                    return res.json();
                }),
        [authToken, environment],
    );
    return fetch;
};
