import { useQuery } from '@tanstack/react-query';
import { useFetch } from '../useFetch';
import queryString from 'query-string';

export function useMydraUserDocuments(
    { filters, pagination, sorting },
    queryOptions,
) {
    const fetch = useFetch();

    const qs = queryString.stringify({
        ...filters,
        ...pagination,
        ...sorting,
    });

    return useQuery({
        queryKey: ['userDocuments', qs],
        queryFn: () => fetch(`/mydra/user-documents?${qs}`),
        ...queryOptions,
    });
}
