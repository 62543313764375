import React from 'react';
import { Box, Grid, Typography, Chip, Paper } from '@mui/material';
import { Section, InfoRow, formatValue, formatLabel } from './shared';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import { formatDistanceToNow } from 'date-fns';

const EPContractDetails = ({ contract }) => {
  const fields = contract.envelopeData.placeholder_fields || [];
  
  const groupedFields = {
    provider: fields.filter(f => f.api_key.startsWith('educationProvider') && 
      !f.api_key.includes('Representative')),
    representative: fields.filter(f => f.api_key.includes('Representative'))
  };

  const getStatusConfig = (status) => {
    switch (status) {
      case 'COMPLETED':
      case 'SIGNED':
        return { 
          icon: <CheckCircleIcon fontSize="small" />, 
          color: 'success',
          label: status
        };
      case 'VOIDED':
      case 'DECLINED':
        return { 
          icon: <CancelIcon fontSize="small" />, 
          color: 'error',
          label: status
        };
      default:
        return { 
          icon: <PendingIcon fontSize="small" />, 
          color: 'warning',
          label: status
        };
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Section title="General Information">
        <Grid container spacing={3}>
          <InfoRow label="Contract ID" value={contract._id} />
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="caption" color="textSecondary" display="block">
                Status
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                <Chip
                  size="small"
                  icon={getStatusConfig(contract.status).icon}
                  label={contract.status}
                  color={getStatusConfig(contract.status).color}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="caption" color="textSecondary" display="block">
                Created At
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {new Date(contract.createdAt).toLocaleString()}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatDistanceToNow(new Date(contract.createdAt), { addSuffix: true })}
              </Typography>
            </Box>
          </Grid>
          {contract.dateSigned && (
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" color="textSecondary" display="block">
                  Signed At
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {new Date(contract.dateSigned).toLocaleString()}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatDistanceToNow(new Date(contract.dateSigned), { addSuffix: true })}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Section>

      <Section title="Education Provider Details">
        <Grid container spacing={3}>
          {groupedFields.provider.map((field, index) => (
            <InfoRow 
              key={`provider-${index}`}
              label={formatLabel(field.api_key.replace('educationProvider', ''))}
              value={formatValue(field.value)}
            />
          ))}
        </Grid>
      </Section>

      <Section title="Representative Information">
        <Grid container spacing={3}>
          {groupedFields.representative.map((field, index) => (
            <InfoRow 
              key={`representative-${index}`}
              label={formatLabel(field.api_key.replace('educationProviderRepresentative', ''))}
              value={formatValue(field.value)}
            />
          ))}
        </Grid>
      </Section>

      <Section title="Recipients">
        <Grid container spacing={2}>
          {contract.recipientsInfo.map((recipient, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  {recipient.userName}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: 0.5 
                }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {recipient.email}
                  </Typography>
                  <Chip 
                    size="small" 
                    label={recipient.recipientRole}
                    color="primary" 
                    variant="outlined"
                    sx={{ alignSelf: 'flex-start' }}
                  />
                </Box>
                <Chip
                  size="small"
                  icon={getStatusConfig(recipient.status).icon}
                  label={getStatusConfig(recipient.status).label}
                  color={getStatusConfig(recipient.status).color}
                  sx={{ alignSelf: 'flex-start', mt: 'auto' }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Section>
    </Box>
  );
};

export default EPContractDetails; 