import { Error, Info } from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useApplicationUpdate } from '../../../../hooks/data-hooks/useApplicationUpdate';
import { useMydraUserDocuments } from '../../../../hooks/data-hooks/useMydraUserDocuments';
import { useMydraUserDocumentUpdate } from '../../../../hooks/data-hooks/useMydraUserDocumentUpdate';
import {
    REVIEWABLE_DOCUMENT_TYPES,
    USER_DOCUMENT_STATUS,
} from './MydraUserScoringDocumentsTable.constants';
import { UpdateDocumentsConfirmationDialog } from './UpdateDocumentsConfirmationDialog';
import { UserDocumentRow } from './UserDocumentRow';
import { UpdateApplicationStatusConfirmationDialog } from './UpdateApplicationStatusConfirmationDialog';

export default function MydraUserScoringDocumentsTable({
    application,
    onApplicationStatusUpdate,
    onUserDocumentsChange,
}) {
    const applicationIsPendingValidation =
        application.statuses.at(-1) === 'PENDING_VALIDATION';
    const [
        updateDocumentsConfirmationDialogOpen,
        setUpdateDocumentsConfirmationDialogOpen,
    ] = useState(false);
    const [
        applicationStatusConfirmationDialogIsOpen,
        setApplicationStatusConfirmationDialogOpen,
    ] = useState(false);

    const {
        data: { data: userDocuments = [] } = {},
        isLoading: isLoadingUserDocuments,
    } = useMydraUserDocuments({
        filters: {
            userId: application.user._id,
            type: REVIEWABLE_DOCUMENT_TYPES,
        },
    });
    const {
        mutateAsync: updateDocuments,
        isError: isErrorUpdatingDocuments,
        reset: resetDocumentsMutation,
    } = useMydraUserDocumentUpdate();
    const {
        mutateAsync: updateApplication,
        isError: isErrorUpdatingApplication,
        reset: resetApplicationMutation,
    } = useApplicationUpdate();

    const documentsThatNeedReview = userDocuments.filter(
        (document) => document.status === USER_DOCUMENT_STATUS.ReviewRequired,
    );
    const documentsToNotifyUser = userDocuments.filter(
        (document) =>
            document.status === USER_DOCUMENT_STATUS.Rejected ||
            document.status === USER_DOCUMENT_STATUS.ExtractionFailed,
    );

    const [documentsToUpdate, setDocumentsToUpdate] = useState([]);
    const requiredButNotReviewedDocuments = documentsThatNeedReview.filter(
        (document) => !documentsToUpdate.find((d) => d._id === document._id),
    );
    const allRequireDocumentsWereReviewed =
        requiredButNotReviewedDocuments.length === 0;

    const applicationIsNotInPendingValidationStatus =
        !applicationIsPendingValidation &&
        'The application is not in pending validation status';
    const noDocumentsToNotifyUser =
        documentsToNotifyUser.length === 0 &&
        'There is no failed or rejected documents to allow sending back the application to the user';
    const noDocumentsToUpdate =
        documentsToUpdate.length === 0 &&
        'There is no documents marked to update';

    const onDocumentReview = (item, newStatus) => {
        resetDocumentsMutation();
        resetApplicationMutation();
        const existingDocument = documentsToUpdate.find(
            (d) => d._id === item._id,
        );

        if (!newStatus) {
            if (existingDocument) {
                setDocumentsToUpdate((prev) =>
                    prev.filter((d) => d._id !== item._id),
                );
            }
            return;
        }

        if (existingDocument) {
            return setDocumentsToUpdate((prev) =>
                prev.map((d) =>
                    d._id === item._id ? { ...d, status: newStatus } : d,
                ),
            );
        }

        return setDocumentsToUpdate((prev) => [
            ...prev,
            { ...item, status: newStatus },
        ]);
    };

    const askConfirmationToUpdateDocuments = () => {
        resetDocumentsMutation();
        setUpdateDocumentsConfirmationDialogOpen(true);
    };

    const askConfirmationToSendBackApplication = () => {
        resetApplicationMutation();
        setApplicationStatusConfirmationDialogOpen(true);
    };

    const onUpdateDocumentsConfirmationDialogClose = async (confirmed) => {
        if (confirmed) {
            try {
                await Promise.all(
                    documentsToUpdate.map((document) =>
                        updateDocuments({
                            id: document._id,
                            update: {
                                status: document.status,
                            },
                        }),
                    ),
                );
                setDocumentsToUpdate([]);
            } catch {
                // Do nothing
            }
        }
        setUpdateDocumentsConfirmationDialogOpen(false);
    };

    const onApplicationStatusConfirmationDialogClose = async (confirmed) => {
        if (confirmed && applicationIsPendingValidation) {
            await updateApplication({
                id: application._id,
                update: {
                    status: 'MISSING_SCORING_DATA',
                },
            });
            onApplicationStatusUpdate();
        }
        setApplicationStatusConfirmationDialogOpen(false);
    };

    useEffect(() => {
        if (
            isLoadingUserDocuments ||
            !typeof onUserDocumentsChange === 'function'
        ) {
            return;
        }

        onUserDocumentsChange(userDocuments);
    }, [userDocuments, onUserDocumentsChange, isLoadingUserDocuments]);

    if (isLoadingUserDocuments) {
        return <CircularProgress />;
    }

    return (
        <>
            <Stack direction="column" spacing={2}>
                <Stack direction="row" spacing={2}>
                    <Typography variant="h6" gutterBottom component="div">
                        User Documents used in scoring
                    </Typography>
                    <Stack
                        direction="column"
                        sx={{
                            marginLeft: 'auto !important',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Stack direction="row" spacing={2}>
                            <Tooltip
                                title={
                                    applicationIsNotInPendingValidationStatus ||
                                    noDocumentsToNotifyUser
                                }
                            >
                                <span>
                                    <Button
                                        sx={{
                                            alignSelf: 'flex-end',
                                        }}
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !applicationIsPendingValidation ||
                                            documentsToNotifyUser.length ===
                                                0 ||
                                            !allRequireDocumentsWereReviewed
                                        }
                                        onClick={
                                            askConfirmationToSendBackApplication
                                        }
                                    >
                                        Send application back to user
                                    </Button>{' '}
                                </span>
                            </Tooltip>
                            <Stack>
                                <Tooltip
                                    title={
                                        applicationIsNotInPendingValidationStatus ||
                                        noDocumentsToUpdate
                                    }
                                >
                                    <span>
                                        <Button
                                            sx={{
                                                alignSelf: 'flex-end',
                                            }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                                askConfirmationToUpdateDocuments()
                                            }
                                            disabled={
                                                applicationIsNotInPendingValidationStatus ||
                                                documentsToUpdate.length ===
                                                    0 ||
                                                !allRequireDocumentsWereReviewed
                                            }
                                        >
                                            Update documents
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        {(isErrorUpdatingDocuments ||
                            isErrorUpdatingApplication) && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Error color="error" />
                                <Typography variant="caption" color="error">
                                    {isErrorUpdatingDocuments
                                        ? 'Error updating documents'
                                        : 'Error updating application'}
                                </Typography>
                            </Stack>
                        )}
                        {requiredButNotReviewedDocuments.length > 0 && (
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Info color="warning" />
                                <Typography
                                    variant="caption"
                                    color="warning"
                                    fontWeight="semibold"
                                >
                                    {requiredButNotReviewedDocuments.length ===
                                    1
                                        ? 'There is still 1 document required to be reviewed'
                                        : `There are still ${requiredButNotReviewedDocuments.length} documents required to be reviewed`}
                                </Typography>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
                {userDocuments.length === 0 ? (
                    <Typography>No documents found</Typography>
                ) : (
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Expiration date</TableCell>
                                    <TableCell>Last update</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userDocuments.map((item) => (
                                    <UserDocumentRow
                                        key={item._id}
                                        item={item}
                                        onDocumentReview={onDocumentReview}
                                        documentsToUpdate={documentsToUpdate}
                                        actionsDisabled={
                                            application.statuses.at(-1) !==
                                            'PENDING_VALIDATION'
                                        }
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Stack>
            <UpdateDocumentsConfirmationDialog
                key={`${application._id}-update-documents`}
                open={updateDocumentsConfirmationDialogOpen}
                documentsToUpdate={documentsToUpdate}
                onClose={onUpdateDocumentsConfirmationDialogClose}
            />
            <UpdateApplicationStatusConfirmationDialog
                key={`${application._id}-update-application-status`}
                open={applicationStatusConfirmationDialogIsOpen}
                documentsToNotifyUser={documentsToNotifyUser}
                onClose={onApplicationStatusConfirmationDialogClose}
            />
        </>
    );
}
