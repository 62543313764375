import React from 'react';
import ISAContractDetails from './ISAContractDetails';
import FixPayContractDetails from './FixPayContractDetails';
import EPContractDetails from './EPContractDetails';

const ContractDetails = ({ contract }) => {
  if (!contract) return null;
  
  if (contract.contractKind === 'education-provider-ep-financing-contract') {
    return <EPContractDetails contract={contract} />;
  }
  
  const isFixPayContract = !contract.title?.startsWith('ISA');
  return isFixPayContract ? (
    <FixPayContractDetails contract={contract} />
  ) : (
    <ISAContractDetails contract={contract} />
  );
};

export default ContractDetails; 