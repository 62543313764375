import React from 'react';
import { Box, Grid, Typography, Chip, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Section, InfoRow, formatValue, formatLabel } from './shared';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import { formatDistanceToNow } from 'date-fns';

const ISAContractDetails = ({ contract }) => {
  const fields = contract.envelopeData.placeholder_fields || [];
  
  const groupedFields = {
    course: fields.filter(f => f.api_key.startsWith('Course')),
    student: fields.filter(f => f.api_key.startsWith('Student')),
    school: fields.filter(f => f.api_key.startsWith('School') && !f.api_key.includes('DropoutPolicy')),
    payment: fields.filter(f => f.api_key.startsWith('ISA') && !f.api_key.startsWith('ISAExampleTable')),
    policy: fields.filter(f => f.api_key.includes('DropoutPolicy')),
    examples: fields.filter(f => f.api_key.startsWith('ISAExampleTable'))
  };

  // Helper function to format example table data
  const formatExampleTable = (examples) => {
    const rows = [];
    const numLines = Math.floor(examples.length / 4);
    
    for (let i = 1; i <= numLines; i++) {
      const rowData = {
        income: examples.find(f => f.api_key === `ISAExampleTable line ${i} gross monthly income`)?.value,
        payment: examples.find(f => f.api_key === `ISAExampleTable line ${i} monthly payment`)?.value,
        numPayments: examples.find(f => f.api_key === `ISAExampleTable line ${i} number of payments`)?.value,
        total: examples.find(f => f.api_key === `ISAExampleTable line ${i} total payment`)?.value
      };
      rows.push(rowData);
    }
    return rows;
  };

  const getStatusConfig = (status) => {
    switch (status) {
      case 'COMPLETED':
      case 'SIGNED':
        return { 
          icon: <CheckCircleIcon fontSize="small" />, 
          color: 'success',
          label: status
        };
      case 'DECLINED':
        return { 
          icon: <CancelIcon fontSize="small" />, 
          color: 'error',
          label: status
        };
      default:
        return { 
          icon: <PendingIcon fontSize="small" />, 
          color: 'warning',
          label: status
        };
    }
  };

  const formatLabel = (apiKey) => {
    // Special cases for acronyms
    const specialCases = {
      'VAT': 'VAT',
      'ISA': 'ISA',
      'CEO': 'CEO'
    };

    return apiKey
      .split(/(?=[A-Z])/)
      .map(part => specialCases[part] || part)
      .join(' ')
      .replace(/[_:]/g, ' ')
      .trim();
  };

  return (
    <Box sx={{ p: 2 }}>
      <Section title="General Information">
        <Grid container spacing={3}>
          <InfoRow label="Contract ID" value={contract._id} />
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="caption" color="textSecondary" display="block">
                Status
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                <Chip
                  size="small"
                  icon={getStatusConfig(contract.status).icon}
                  label={contract.status}
                  color={getStatusConfig(contract.status).color}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="caption" color="textSecondary" display="block">
                Created At
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {new Date(contract.createdAt).toLocaleString()}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatDistanceToNow(new Date(contract.createdAt), { addSuffix: true })}
              </Typography>
            </Box>
          </Grid>
          {contract.dateSigned && (
            <Grid item xs={12} sm={6} md={4}>
              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" color="textSecondary" display="block">
                  Signed At
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                  {new Date(contract.dateSigned).toLocaleString()}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {formatDistanceToNow(new Date(contract.dateSigned), { addSuffix: true })}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </Section>

      <Section title="School Information">
        <Grid container spacing={3}>
          {groupedFields.school.map((field, index) => (
            <InfoRow 
              key={`school-${index}`}
              label={formatLabel(field.api_key)}
              value={formatValue(field.value)}
            />
          ))}
        </Grid>
      </Section>

      <Section title="Course Details">
        <Grid container spacing={3}>
          {groupedFields.course.map((field, index) => (
            <InfoRow 
              key={`course-${index}`}
              label={formatLabel(field.api_key)}
              value={formatValue(field.value)}
            />
          ))}
        </Grid>
      </Section>

      <Section title="Student Information">
        <Grid container spacing={3}>
          {groupedFields.student.map((field, index) => (
            <InfoRow 
              key={`student-${index}`}
              label={formatLabel(field.api_key)}
              value={formatValue(field.value)}
            />
          ))}
        </Grid>
      </Section>

      <Section title="ISA Terms">
        <Grid container spacing={3}>
          {groupedFields.payment.map((field, index) => {
            if (field.api_key === 'ISARepaymentTriggerDate') {
              return (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="caption" color="textSecondary" display="block">
                      ISA Repayment Trigger Date
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {formatValue(field.value)}
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      {formatDistanceToNow(new Date(field.value), { addSuffix: true })}
                    </Typography>
                  </Box>
                </Grid>
              );
            }
            return (
              <InfoRow 
                key={`payment-${index}`}
                label={formatLabel(field.api_key)}
                value={formatValue(field.value)}
              />
            );
          })}
        </Grid>
      </Section>

      <Section title="Dropout Policy">
        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
          {groupedFields.policy[0]?.value || 'No policy specified'}
        </Typography>
      </Section>

      <Section title="Payment Examples">
        <TableContainer component={Paper} variant="outlined">
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow sx={{ backgroundColor: 'grey.50' }}>
                <TableCell sx={{ fontWeight: 'bold' }}>Monthly Income</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Monthly Payment</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Number of Payments</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Total Payment</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {formatExampleTable(groupedFields.examples).map((row, index) => (
                <TableRow 
                  key={index}
                  sx={{ 
                    '&:nth-of-type(odd)': { backgroundColor: 'grey.50' },
                    '&:hover': { backgroundColor: 'action.hover' }
                  }}
                >
                  <TableCell>{row.income}</TableCell>
                  <TableCell>{row.payment}</TableCell>
                  <TableCell>{row.numPayments}</TableCell>
                  <TableCell>{row.total}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>

      <Section title="Recipients">
        <Grid container spacing={2}>
          {contract.recipientsInfo.map((recipient, index) => (
            <Grid item xs={12} md={6} lg={4} key={index}>
              <Paper 
                variant="outlined" 
                sx={{ 
                  p: 2,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'medium' }}>
                  {recipient.userName}
                </Typography>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  gap: 0.5 
                }}>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ wordBreak: 'break-all' }}
                  >
                    {recipient.email}
                  </Typography>
                  <Chip 
                    size="small" 
                    label={recipient.recipientRole}
                    color="primary" 
                    variant="outlined"
                    sx={{ alignSelf: 'flex-start' }}
                  />
                </Box>
                <Chip
                  size="small"
                  icon={getStatusConfig(recipient.status).icon}
                  label={getStatusConfig(recipient.status).label}
                  color={getStatusConfig(recipient.status).color}
                  sx={{ alignSelf: 'flex-start', mt: 'auto' }}
                />
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Section>
    </Box>
  );
};

export default ISAContractDetails; 