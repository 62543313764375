import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

export const Section = ({ title, children }) => (
  <Box sx={{ mb: 3 }}>
    <Typography 
      variant="h6" 
      gutterBottom 
      sx={{ 
        color: 'primary.main',
        borderBottom: '1px solid',
        borderColor: 'divider',
        pb: 1,
        mb: 2 
      }}
    >
      {title}
    </Typography>
    <Box 
      sx={{ 
        p: 2,
        backgroundColor: 'background.default',
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      {children}
    </Box>
  </Box>
);

export const InfoRow = ({ label, value }) => (
  <Grid item xs={12} sm={6} md={4}>
    <Box sx={{ mb: 2 }}>
      <Typography variant="caption" color="textSecondary" display="block">
        {label}
      </Typography>
      <Typography variant="body2" sx={{ fontWeight: 500 }}>
        {value}
      </Typography>
    </Box>
  </Grid>
);

export const formatValue = (value) => {
  if (!value) return '-';
  return value;
};

export const formatLabel = (apiKey) => {
  return apiKey
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/[_:]/g, ' ')
    .trim();
}; 