import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Checkbox,
    Snackbar,
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoIcon from '@mui/icons-material/Info'; // Importing InfoIcon
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import CategoryIcon from '@mui/icons-material/Category';
import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import QualityScoreExplanationModal from '../../components_mydra/QualityScoreExplanationModal/QualityScoreExplanationModal';
import QualityScoreBreakdown from '../../components_mydra/QualityScoreBreakdwown/QualityScoreBreakdown';
import LabelIcon from '@mui/icons-material/Label';

const MydraProviderPage = () => {

    // Add this variable at the top of your component
    const MAX_QUALITY_SCORE = 32;

    const { providerId, providerName } = useParams();
    const [courses, setCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState(new Set());

    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isProduction, setIsProduction] = useState(true);
    const [isLoadingCourses, setIsLoadingCourses] = useState(false);
    const [orderBy, setOrderBy] = useState('createdAt');
    const [order, setOrder] = useState('desc');
    const theme = useTheme();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [isLoading, setIsLoading] = useState(false);
    const [openPublishDialog, setOpenPublishDialog] = useState(false);
    const [openUnpublishDialog, setOpenUnpublishDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openExplanationModal, setOpenExplanationModal] = useState(false); // State for the explanation modal
    const [openTagDialog, setOpenTagDialog] = useState(false);
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [isAddingTags, setIsAddingTags] = useState(false);
    const [openRemoveTagDialog, setOpenRemoveTagDialog] = useState(false);
    const [selectedTagToRemove, setSelectedTagToRemove] = useState('');
    const [isRemovingTags, setIsRemovingTags] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const [loadingState, setLoadingState] = useState({
        isLoading: false,
        type: '', // 'publish' or 'unpublish'
    });

    // Add new state for auth error
    const [authError, setAuthError] = useState(false);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken && userEmail) {
            console.log('Fetching courses');
            fetchCourses();
        }
    }, [authToken, userEmail, isProduction]);

    const fetchCourses = async () => {
        try {
            setIsLoadingCourses(true);
            setAuthError(false); // Reset auth error state

            const response = await fetch(`${API_BASE_URL}/mydra/provider/${providerId}/${providerName}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });

            if (response.status === 401) {
                setAuthError(true);
                setSnackbar({
                    open: true,
                    message: 'Your session has expired. Please refresh the page to continue and try again.',
                    severity: 'error',
                    action: (
                        <Button color="inherit" size="small" onClick={() => window.location.reload()}>
                            Refresh Page
                        </Button>
                    )
                });
                return;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Courses:', responseData);
            setCourses(responseData);
        } catch (error) {
            console.error('Error fetching courses:', error);
            setSnackbar({
                open: true,
                message: 'Failed to fetch courses. Please try again.',
                severity: 'error'
            });
        } finally {
            setIsLoadingCourses(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handleRowClick = (courseId) => {
        const url = `/mydra/course/${courseId}${!isProduction ? '?env=Staging' : ''}`;
        console.log('Navigating to:', url);
        window.open(url, '_blank'); // '_blank' opens the URL in a new tab
    };

    const tableRowStyle = {
      cursor: 'pointer',
      '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
      '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
      }
  };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const newOrder = isAsc ? 'desc' : 'asc';
        
        // For createdAt, prefer 'desc' as the toggle order
        if (property === 'createdAt' && orderBy !== 'createdAt') {
            setOrder('desc');
        } else {
            setOrder(newOrder);
        }
        setOrderBy(property);
    };

    const sortedCourses = React.useMemo(() => {
        const comparator = (a, b) => {
            if (orderBy === 'createdAt') {
                return new Date(b.createdAt) - new Date(a.createdAt); // Changed order for dates
            } else if (orderBy === 'name') {
                return a.name.localeCompare(b.name);
            } else if (orderBy === 'tags') {
                const aTags = a.tags?.map(tag => tag.name).join(', ') || '';
                const bTags = b.tags?.map(tag => tag.name).join(', ') || '';
                return aTags.localeCompare(bTags);
            } else if (orderBy === 'categories') {
                const aCategories = a.categories.map(cat => cat.name).join(', ');
                const bCategories = b.categories.map(cat => cat.name).join(', ');
                return aCategories.localeCompare(bCategories);
            } else if (orderBy === 'published') {
                return a.published === b.published ? 0 : a.published ? -1 : 1;
            } else if (orderBy === 'cost') {
                // Get the first cohort's cost or default to 0
                const aCost = a.cohorts?.[0]?.cost?.amount || 0;
                const bCost = b.cohorts?.[0]?.cost?.amount || 0;
                return aCost - bCost;
            } else {
                return 0;
            }
        };

        return [...courses].sort((a, b) => {
            const result = comparator(a, b);
            return order === 'asc' ? result : -result;
        });
    }, [courses, order, orderBy]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd HH:mm');
    };

    const formatDateCohorts = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
      };

    const publishedCourses = courses.filter(course => course.published);
    const unpublishedCourses = courses.filter(course => !course.published);

    const categoryCounts = courses.reduce((acc, course) => {
        course.categories.forEach(category => {
            if (!acc[category.name]) {
                acc[category.name] = 0;
            }
            acc[category.name]++;
        });
        return acc;
    }, {});
    
    const sortedCategoryCounts = Object.entries(categoryCounts).sort((a, b) => b[1] - a[1]);

    const getQualityScoreColor = (score) => {
        const percentage = (score / MAX_QUALITY_SCORE) * 100;

        if (percentage > 75) return 'darkgreen';
        if (percentage > 50) return 'green';
        if (percentage > 25) return 'orange';
        return 'darkred';
    };

    // Add this function to calculate the percentage
    const calculateQualityScorePercentage = (score) => {
        return Math.round((score / MAX_QUALITY_SCORE) * 100);
    };



    const handleSelectCourse = (event, courseId) => {
        event.stopPropagation();  // This stops the click event from bubbling up to the parent row

        const newSelection = new Set(selectedCourses);
        if (newSelection.has(courseId)) {
          newSelection.delete(courseId);
        } else {
          newSelection.add(courseId);
        }
        setSelectedCourses(newSelection);
      };
    
      const handleBulkPublish = async (publish) => {
        setLoadingState({ isLoading: true, type: publish ? 'publish' : 'unpublish' });

        const newPublishedStatus = publish;
        const nonTranslationFields = {
            published: newPublishedStatus,
        };

        const payload = {
            nonTranslationFields : nonTranslationFields,
        };

        try {
            await Promise.all(
                Array.from(selectedCourses).map(courseId =>
                    fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                        method: 'PUT',
                        headers: { 
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                            'X-User-Email': userEmail,
                            'X-Environment': isProduction ? 'Production' : 'Staging'
                        },
                        body: JSON.stringify(payload),
                    })
                )
            );
            setSnackbar({ open: true, message: `Courses successfully ${publish ? 'published' : 'unpublished'}`, severity: 'success' });
            setSelectedCourses(new Set()); // Clear selection after successful operation
            fetchCourses();
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to update courses', severity: 'error' });
            console.error('Failed to publish/unpublish courses', error);
        } finally {
            setLoadingState({ isLoading: false, type: '' });
        }
      };
    
    const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });
    
    const handleOpenPublishDialog = () => setOpenPublishDialog(true);
    const handleClosePublishDialog = () => setOpenPublishDialog(false);
    
    const handleOpenUnpublishDialog = () => setOpenUnpublishDialog(true);
    const handleCloseUnpublishDialog = () => setOpenUnpublishDialog(false);

    const initiatePublish = (publish) => {
        if (publish) {
            handleOpenPublishDialog();
        } else {
            handleOpenUnpublishDialog();
        }
    };

    const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

    const handleBulkDelete = async () => {
        setLoadingState({ isLoading: true, type: 'delete' });
        try {
            await Promise.all(
                Array.from(selectedCourses).map(courseId =>
                    fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'X-User-Email': userEmail,
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                        },
                    })
                )
            );
            setSnackbar({ open: true, message: `${selectedCourses.size} course(s) successfully deleted`, severity: 'success' });
            setSelectedCourses(new Set()); // Clear selection after successful operation
            fetchCourses(); // Refresh the course list
        } catch (error) {
            setSnackbar({ open: true, message: 'Failed to delete courses', severity: 'error' });
            console.error('Failed to delete courses', error);
        } finally {
            setLoadingState({ isLoading: false, type: '' });
            handleCloseDeleteDialog();
        }
    };

    const fetchAvailableTags = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setAvailableTags(data);
            }
        } catch (error) {
            console.error('Error fetching tags:', error);
            setSnackbar({ open: true, message: 'Failed to fetch available tags', severity: 'error' });
        }
    };

    const handleAddTagsToCourses = async () => {
        if (!selectedTag || selectedCourses.size === 0) return;

        setIsAddingTags(true);
        try {
            await Promise.all(Array.from(selectedCourses).map(async (courseId) => {
                const course = courses.find(c => c._id === courseId);
                if (!course) return;

                // Get existing tags and add the new one (avoiding duplicates)
                const existingTagSlugs = course.tags?.map(tag => tag.slug) || [];
                const newTagSlug = selectedTag;
                
                if (!existingTagSlugs.includes(newTagSlug)) {
                    const nonTranslationFields = {
                        tags: [...existingTagSlugs, newTagSlug]
                    };

                    const payload = {
                        nonTranslationFields,
                        language: 'en'
                    };

                    await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                        method: 'PUT',
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                        },
                        body: JSON.stringify(payload),
                    });
                }
            }));

            setSnackbar({ 
                open: true, 
                message: `Tags added successfully to ${selectedCourses.size} course(s)`, 
                severity: 'success' 
            });
            setOpenTagDialog(false);
            setSelectedTag('');
            fetchCourses(); // Refresh the course list
        } catch (error) {
            console.error('Error adding tags:', error);
            setSnackbar({ 
                open: true, 
                message: 'Failed to add tags to courses', 
                severity: 'error' 
            });
        } finally {
            setIsAddingTags(false);
        }
    };

    const handleRemoveTagsFromCourses = async () => {
        if (!selectedTagToRemove || selectedCourses.size === 0) return;

        setIsRemovingTags(true);
        try {
            await Promise.all(Array.from(selectedCourses).map(async (courseId) => {
                const course = courses.find(c => c._id === courseId);
                if (!course) return;

                // Filter out the selected tag
                const updatedTagSlugs = course.tags
                    ?.filter(tag => tag.slug !== selectedTagToRemove)
                    .map(tag => tag.slug) || [];

                const nonTranslationFields = {
                    tags: updatedTagSlugs
                };

                const payload = {
                    nonTranslationFields,
                    language: 'en'
                };

                await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                    },
                    body: JSON.stringify(payload),
                });
            }));

            setSnackbar({ 
                open: true, 
                message: `Tag removed successfully from ${selectedCourses.size} course(s)`, 
                severity: 'success' 
            });
            setOpenRemoveTagDialog(false);
            setSelectedTagToRemove('');
            fetchCourses(); // Refresh the course list
        } catch (error) {
            console.error('Error removing tags:', error);
            setSnackbar({ 
                open: true, 
                message: 'Failed to remove tag from courses', 
                severity: 'error' 
            });
        } finally {
            setIsRemovingTags(false);
        }
    };

    useEffect(() => {
        if (openTagDialog || openRemoveTagDialog) {
            fetchAvailableTags();
        }
    }, [openTagDialog, openRemoveTagDialog]);

    return (
        <Container maxWidth={false} sx={{ mt: 4, px: 4 }}>
            <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                }}
            >
                Courses by {providerName}
            </Typography>

            <Box display="flex" alignItems="center" mb={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact all live courses.
                </Typography>
            )}

    
          {!isLoadingCourses ? (
             <> 
                <Box sx={{ mb: 4 }}>
                  <Paper sx={{ p: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>                
                    <Typography variant="h6" gutterBottom>Total Courses</Typography>
                      <Box>
                        <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <Typography variant="h5" component="span" sx={{ fontWeight: 'bold', mr: 1 }}>{courses.length}</Typography>
                                <Typography variant="body1" component="span">Courses</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                <FiberManualRecordIcon sx={{ color: 'green', mr: 1 }} />
                                <Typography variant="h6" component="span" sx={{ mr: 1 }}>{publishedCourses.length}</Typography>
                                <Typography variant="body1" component="span">Published</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <FiberManualRecordIcon sx={{ color: 'red', mr: 1 }} />
                                <Typography variant="h6" component="span" sx={{ mr: 1 }}>{unpublishedCourses.length}</Typography>
                                <Typography variant="body1" component="span">Unpublished</Typography>
                            </Box>
                        </Typography>
                    </Box>
                  </Paper>  
                  <Paper sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>Courses by Category</Typography>
                          <Box sx={{ mt: 4 }}>
                              <ResponsiveContainer width="100%" height={400}>
                                  <BarChart data={sortedCategoryCounts.map(([name, value]) => ({ name, value }))}
                                    margin={{ bottom: 110, left: 30 }}
                                    >
                                      <CartesianGrid strokeDasharray="3 3" />
                                      <XAxis dataKey="name" angle={-40} textAnchor="end" interval={0} />
                                      <YAxis />
                                      <RechartsTooltip />
                                      <Bar dataKey="value" fill="#8884d8" />
                                  </BarChart>
                              </ResponsiveContainer>
                          </Box>
                  </Paper>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    variant="outlined"
                    startIcon={<FiberManualRecordIcon style={{ color: 'green' }} />}
                    onClick={() => initiatePublish(true)}
                    disabled={selectedCourses.size === 0 || loadingState.isLoading}
                    sx={{ marginRight: 2 }}
                >
                    Publish Selected
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<FiberManualRecordIcon style={{ color: 'red' }} />}
                    onClick={() => initiatePublish(false)}
                    disabled={selectedCourses.size === 0 || loadingState.isLoading}
                    sx={{ marginRight: 2 }}

                >
                    Unpublish Selected
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDeleteDialog}
                    disabled={selectedCourses.size === 0 || loadingState.isLoading}
                >
                    Delete Selected
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<LabelIcon />}
                    onClick={() => setOpenTagDialog(true)}
                    disabled={selectedCourses.size === 0 || loadingState.isLoading}
                    sx={{ marginLeft: 2 }}
                >
                    Add Tags to Selected
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<LabelIcon color="error" />}
                    onClick={() => setOpenRemoveTagDialog(true)}
                    disabled={selectedCourses.size === 0 || loadingState.isLoading}
                    sx={{ marginLeft: 2 }}
                >
                    Remove Tags from Selected
                </Button>
                {loadingState.isLoading && (
                    <CircularProgress size={24} sx={{ ml: 2 }} />
                )}
            </Box>

            </>
          ) : null}

            {isLoadingCourses ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : (
              sortedCourses.length > 0 ? (
                    <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table sx={{ minWidth: 1200 }}>
                            <TableHead>
                                <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        indeterminate={selectedCourses.size > 0 && selectedCourses.size < courses.length}
                                        checked={courses.length > 0 && selectedCourses.size === courses.length}
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                const newSelectedCourses = new Set(courses.map(course => course._id));
                                                setSelectedCourses(newSelectedCourses);
                                            } else {
                                                setSelectedCourses(new Set());
                                            }
                                        }}
                                    />
                                    </TableCell>
                                    <TableCell>#</TableCell>
                                    <TableCell>Provider</TableCell>
                                    <TableCell sortDirection={orderBy === 'name' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleRequestSort('name')}
                                        >
                                            Course Name
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell sortDirection={orderBy === 'categories' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'categories'}
                                            direction={orderBy === 'categories' ? order : 'asc'}
                                            onClick={() => handleRequestSort('categories')}
                                        >
                                            Category
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'tags' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'tags'}
                                            direction={orderBy === 'tags' ? order : 'asc'}
                                            onClick={() => handleRequestSort('tags')}
                                        >
                                            Tags
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'cost' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'cost'}
                                            direction={orderBy === 'cost' ? order : 'asc'}
                                            onClick={() => handleRequestSort('cost')}
                                        >
                                            Cost
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Next cohort</TableCell>
                                    <TableCell sortDirection={orderBy === 'createdAt' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'createdAt'}
                                            direction={orderBy === 'createdAt' ? order : 'desc'}
                                            onClick={() => handleRequestSort('createdAt')}
                                        >
                                            Created At
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Agreement</TableCell>
                                    <TableCell sortDirection={orderBy === 'qualityScore' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'qualityScore'}
                                            direction={orderBy === 'qualityScore' ? order : 'asc'}
                                            onClick={() => handleRequestSort('qualityScore')}
                                        >
                                            Quality Score
                                            <Tooltip 
                                                title="How the quality score is calculated" 
                                                arrow
                                                componentsProps={{
                                                    tooltip: {
                                                        sx: {
                                                            bgcolor: 'grey.700',
                                                            color: 'common.white',
                                                            fontSize: 12,
                                                        },
                                                    },
                                                }}
                                                >
                                                <InfoIcon
                                                    onClick={(e) => { e.stopPropagation(); setOpenExplanationModal(true); }}
                                                    sx={{ ml: 1, cursor: 'pointer' }}
                                                />
                                            </Tooltip>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell sortDirection={orderBy === 'published' ? order : false}>
                                        <TableSortLabel
                                            active={orderBy === 'published'}
                                            direction={orderBy === 'published' ? order : 'asc'}
                                            onClick={() => handleRequestSort('published')}
                                        >
                                            Published
                                        </TableSortLabel>
                                    </TableCell>       
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedCourses.map((course, index) => (
                                    <TableRow key={course._id} onClick={() => handleRowClick(course._id)} sx={tableRowStyle}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedCourses.has(course._id)}
                                                onChange={(event) => handleSelectCourse(event, course._id)}
                                                onClick={(event) => event.stopPropagation()}  // Prevent the event from bubbling up
                                            />
                                        </TableCell>
                                        <TableCell>{index + 1}</TableCell> {/* Display the index, starting from 1 */}
                                        {/* <TableCell>
                                            {course.educationProvider.logoUrl ? (
                                                <Box display="flex" alignItems="center">
                                                    <img
                                                        src={course.educationProvider.logoUrl}
                                                        alt="Provider Logo"
                                                        style={{ width: 50, height: 50, marginRight: 8 }} // Adjust size and spacing as needed
                                                    />     
                                                </Box>
                                            ) : (
                                                <Typography variant="body2">No Logo Available</Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>{course.educationProvider.name}</TableCell> */}
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                {course.educationProvider.logoUrl ? (
                                                <img
                                                    src={course.educationProvider.logoUrl}
                                                    alt="Provider Logo"
                                                    style={{ width: 40, height: 40, marginRight: 8 }}
                                                />
                                                ) : (
                                                <Box width={40} height={40} bgcolor="grey.300" marginRight={1} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography variant="caption">No Logo</Typography>
                                                </Box>
                                                )}
                                                <Typography variant="body2">{course.educationProvider.name}</Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>{course.name}</TableCell>
                                        <TableCell>{course.categories.map(category => category.name).join(', ')}</TableCell> {/* Displaying Category */}
                                        <TableCell>
                                            {course.tags && course.tags.length > 0 ? (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {course.tags.map(tag => (
                                                        <Typography
                                                            key={tag._id}
                                                            variant="body2"
                                                            sx={{
                                                                backgroundColor: 'primary.main',
                                                                color: 'white',
                                                                padding: '2px 8px',
                                                                borderRadius: '12px',
                                                                fontSize: '0.75rem'
                                                            }}
                                                        >
                                                            {tag.name}
                                                        </Typography>
                                                    ))}
                                                </Box>
                                            ) : (
                                                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                                    No tags
                                                </Typography>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                        {course.cohorts && course.cohorts[0]?.cost
                                            ? `${(course.cohorts[0].cost.amount / 100).toFixed(2)} ${course.cohorts[0].cost.currency}`
                                            : 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                        {course.cohorts && course.cohorts[0]?.startDate && course.cohorts[0]?.endDate
                                            ? `${formatDateCohorts(course.cohorts[0].startDate)} - ${formatDateCohorts(course.cohorts[0].endDate)}`
                                            : 'Self-paced'}
                                        </TableCell>
                                        <TableCell>{formatDate(course.createdAt)}</TableCell> {/* Formatted Created At */}
                                        <TableCell>
                                            {course.agreements && course.agreements.length > 0 ? (
                                                course.agreements.map((agreement, index) => (
                                                <Box key={agreement._id} sx={{ display: 'flex', alignItems: 'center', mb: index < course.agreements.length - 1 ? 1 : 0 }}>
                                                    <FiberManualRecordIcon sx={{ color: 'green', mr: 1, fontSize: 12 }} />
                                                    <Typography variant="body2">{agreement.name}</Typography>
                                                </Box>
                                                ))
                                            ) : (
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <FiberManualRecordIcon sx={{ color: 'red', mr: 1, fontSize: 12 }} />
                                                <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
                                                    No agreement
                                                </Typography>
                                                </Box>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Tooltip
                                                title={<QualityScoreBreakdown 
                                                    breakdown={course.qualityScoreBreakdown} 
                                                    qualityScoreCalculatedAt={course.qualityScoreCalculatedAt}
                                                />}
                                                arrow
                                                componentsProps={{
                                                tooltip: {
                                                    sx: {
                                                    bgcolor: 'background.paper',
                                                    '& .MuiTooltip-arrow': {
                                                        color: 'background.paper',
                                                    },
                                                    boxShadow: theme.shadows[1],
                                                    maxWidth: 'none', // Removes max-width constraint
                                                    width: '300px', // Adjust this value as needed
                                                    },
                                                },
                                                }}
                                            >
                                            <Typography
                                                variant="body2"
                                                sx={{ color: getQualityScoreColor(course.qualityScore), fontWeight: 'bold', cursor: 'help' }}
                                            >
                                                {`${calculateQualityScorePercentage(course.qualityScore)}%`}
                                            </Typography>
                                            </Tooltip>
                                            </TableCell>

                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <Typography variant="body2" component="span">
                                                    {course.published ? "Yes" : "No"}
                                                </Typography>
                                                <FiberManualRecordIcon
                                                    sx={{ color: course.published ? 'green' : 'red', ml: 1 }}
                                                />
                                            </Box>
                                        </TableCell>                                    
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Paper elevation={3} sx={{ p: 3, mt: 10, display: 'flex', flexDirection: 'column', alignItems: 'center', backgroundColor: theme.palette.background.paper }}>
                        <InfoIcon sx={{ fontSize: 60, color: theme.palette.primary.main }} />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            No Courses Available
                        </Typography>
                        <Typography variant="subtitle1">
                            This provider doesn't have any courses yet.
                        </Typography>
                    </Paper>
                )
            )}

            <Snackbar
                open={snackbar.open}
                autoHideDuration={snackbar.action ? null : 6000} // Don't auto-hide if there's an action
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert 
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
                    severity={snackbar.severity}
                    action={snackbar.action}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
            <Dialog
                open={openPublishDialog}
                onClose={handleClosePublishDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Publish Selected Courses?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to publish the selected courses? This action will make them live.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePublishDialog}>Cancel</Button>
                    <Button onClick={() => {handleBulkPublish(true); handleClosePublishDialog();}} autoFocus>
                        Publish
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openUnpublishDialog}
                onClose={handleCloseUnpublishDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Unpublish Selected Courses?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to unpublish the selected courses? This action will remove them from being live.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUnpublishDialog}>Cancel</Button>
                    <Button onClick={() => {handleBulkPublish(false); handleCloseUnpublishDialog();}} autoFocus>
                        Unpublish
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDeleteDialog}
                onClose={handleCloseDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Bulk Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete {selectedCourses.size} selected course(s)? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} disabled={loadingState.isLoading && loadingState.type === 'delete'}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleBulkDelete} 
                        color="error" 
                        autoFocus
                        disabled={loadingState.isLoading && loadingState.type === 'delete'}
                        startIcon={loadingState.isLoading && loadingState.type === 'delete' ? <CircularProgress size={20} color="inherit" /> : <DeleteIcon />}
                    >
                        {loadingState.isLoading && loadingState.type === 'delete' ? 'Deleting...' : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>
            <QualityScoreExplanationModal
                open={openExplanationModal}
                handleClose={() => setOpenExplanationModal(false)}
            />
            <Dialog open={openTagDialog} onClose={() => setOpenTagDialog(false)}>
                <DialogTitle>Add Tags to Selected Courses</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2, minWidth: 300 }}>
                        <FormControl fullWidth>
                            <InputLabel>Select Tag</InputLabel>
                            <Select
                                value={selectedTag}
                                onChange={(e) => setSelectedTag(e.target.value)}
                                label="Select Tag"
                            >
                                {availableTags.map((tag) => (
                                    <MenuItem key={tag.slug} value={tag.slug}>
                                        {tag.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
                            Selected courses: {selectedCourses.size}
                        </Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenTagDialog(false)} disabled={isAddingTags}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleAddTagsToCourses} 
                        variant="contained" 
                        disabled={!selectedTag || isAddingTags}
                        startIcon={isAddingTags ? <CircularProgress size={20} /> : null}
                    >
                        {isAddingTags ? 'Adding Tags...' : 'Add Tags'}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openRemoveTagDialog} onClose={() => setOpenRemoveTagDialog(false)}>
                <DialogTitle>Remove Tag from Selected Courses</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <InputLabel>Select Tag to Remove</InputLabel>
                        <Select
                            value={selectedTagToRemove}
                            onChange={(e) => setSelectedTagToRemove(e.target.value)}
                            label="Select Tag to Remove"
                        >
                            {availableTags.map(tag => (
                                <MenuItem key={tag.slug} value={tag.slug}>
                                    {tag.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenRemoveTagDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleRemoveTagsFromCourses}
                        disabled={!selectedTagToRemove || isRemovingTags}
                        variant="contained"
                    >
                        {isRemovingTags ? 'Removing...' : 'Remove Tag'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default MydraProviderPage;


