import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import app from '../firebaseConfig';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [timeUntilRefresh, setTimeUntilRefresh] = useState(null);
    const auth = getAuth(app);
    const provider = new GoogleAuthProvider();

    const signIn = async () => {
        try {
            console.log('🔐 Starting Google sign-in...');
            const result = await signInWithPopup(auth, provider);
            const token = await result.user.getIdToken();
            setAuthToken(token);
            setUserEmail(result.user.email);
            console.log('✅ Sign-in successful');
            return result;
        } catch (error) {
            console.error('❌ Sign-in error:', error);
            throw error;
        }
    };

    const logTokenInfo = (token, prefix = '') => {
        const tokenData = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = new Date(tokenData.exp * 1000);
        const timeUntilExpiry = expirationTime - new Date();
        
        console.log(`${prefix} Token info:`, {
            expiresAt: expirationTime.toLocaleString(),
            minutesUntilExpiry: Math.floor(timeUntilExpiry / 60000),
            secondsUntilExpiry: Math.floor(timeUntilExpiry / 1000),
            tokenIssued: new Date(tokenData.iat * 1000).toLocaleString()
        });
        return { expirationTime, timeUntilExpiry };
    };

    const setupTokenRefresh = useCallback(async (user) => {
        try {
            const token = await user.getIdToken();
            const { expirationTime, timeUntilExpiry } = logTokenInfo(token, '🔑 Initial');
            
            setAuthToken(token);
            setUserEmail(user.email);

            // Set up countdown timer
            const countdownInterval = setInterval(() => {
                const timeLeft = expirationTime - new Date();
                const minutesLeft = Math.floor(timeLeft / 60000);
                const secondsLeft = Math.floor((timeLeft % 60000) / 1000);
                
                if (timeLeft > 0) {
                    setTimeUntilRefresh(`${minutesLeft}m ${secondsLeft}s`);
                    console.log(`⏳ Token refresh in: ${minutesLeft}m ${secondsLeft}s`);
                }
            }, 10000); // Update every 10 seconds

            // Set up token refresh
            const refreshTimeout = setTimeout(async () => {
                console.log('🔄 Initiating token refresh...');
                try {
                    const newToken = await user.getIdToken(true);
                    console.log('✅ Token refreshed successfully');
                    logTokenInfo(newToken, '🆕 New');
                    setAuthToken(newToken);
                    
                    // Setup next refresh cycle
                    clearInterval(countdownInterval);
                    setupTokenRefresh(user);
                } catch (error) {
                    console.error('❌ Token refresh failed:', error);
                }
            }, timeUntilExpiry - (5 * 60 * 1000)); // Refresh 5 minutes before expiry

            return () => {
                clearTimeout(refreshTimeout);
                clearInterval(countdownInterval);
            };
        } catch (error) {
            console.error('❌ Token setup error:', error);
        }
    }, []);

    useEffect(() => {
        console.log('🚀 Auth state change detected');
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                console.log('👤 User authenticated:', user.email);
                await setupTokenRefresh(user);
            } else {
                console.log('👋 User signed out');
                setAuthToken(null);
                setUserEmail(null);
                setTimeUntilRefresh(null);
            }
            setIsLoading(false);
        });

        return () => {
            console.log('🧹 Cleaning up auth subscriptions');
            unsubscribe();
        };
    }, [auth, setupTokenRefresh]);

    return (
        <AuthContext.Provider value={{ 
            authToken, 
            userEmail, 
            isLoading, 
            signIn,
            isAuthenticated: !!authToken,
            timeUntilRefresh // Expose the timer to components if needed
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
}; 