import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  CircularProgress,
  FormControl,
  InputLabel,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  Divider,
  Chip,
  TableContainer,
  Alert,
  Switch,
  Snackbar,
  Skeleton
} from '@mui/material';
import { getAuth } from 'firebase/auth';
import CloseIcon from '@mui/icons-material/Close';
import ContractDetails from '../../components_mydra/ContractDetails/ContractDetails';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import PendingIcon from '@mui/icons-material/Pending';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { formatDistanceToNow } from 'date-fns';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import SchoolIcon from '@mui/icons-material/School';

// Create a styled table component
const StyledTable = styled(Table)(({ theme }) => ({
  '& .MuiTableCell-head': {
    backgroundColor: theme.palette.grey[100],
    fontWeight: 600,
  },
  '& .MuiTableCell-root': {
    padding: theme.spacing(1.5),
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function MydraContractsPage() {
  const [contracts, setContracts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    environment: 'Production',
    applicationId: '',
    educationProviderId: '',
    status: '',
    contractKind: ''
  });

  const auth = getAuth();
  const [authToken, setAuthToken] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [selectedContract, setSelectedContract] = useState(null);
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [loadingContractId, setLoadingContractId] = useState(null);
  const [educationProviders, setEducationProviders] = useState([]);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [sorting, setSorting] = useState({
    sortBy: 'updatedAt',
    sortOrder: 'desc'
  });
  const [totalPages, setTotalPages] = useState(1);
  const [copyFeedback, setCopyFeedback] = useState(false);

  // Contract type options
  const contractTypes = [
    { value: '', label: 'All Types' },
    { value: 'customer-financing-contract', label: 'Customer Financing' },
    { value: 'education-provider-ep-financing-contract', label: 'Education Provider' }
  ];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
        setUserEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchContracts = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      
      // Add pagination params
      params.append('page', filters.page.toString());
      params.append('limit', '10');
      
      // Add sorting params
      params.append('sortBy', sorting.sortBy);
      params.append('sortOrder', sorting.sortOrder);
      
      // Add filter params
      if (filters.applicationId) params.append('applicationId', filters.applicationId);
      if (filters.educationProviderId) params.append('educationProviderId', filters.educationProviderId);
      if (filters.status) params.append('status', filters.status);
      if (filters.contractKind) params.append('contractKind', filters.contractKind);

      // Log the full URL and params
      console.log('Fetching contracts with URL:', 
        `${process.env.REACT_APP_API_BASE_URL}/api/contracts?${params.toString()}`);
      console.log('Current sorting:', sorting);
      console.log('Request params:', Object.fromEntries(params.entries()));

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/contracts?${params.toString()}`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'X-User-Email': userEmail,
            'X-Environment': filters.environment
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch contracts');
      }

      const data = await response.json();
      console.log('Received contracts:', data);
      setContracts(data.data);
      if (data.meta?.total) {
        setTotalPages(Math.ceil(data.meta.total / data.meta.limit));
      }
    } catch (err) {
      console.error('Error fetching contracts:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchContracts();
    }
  }, [authToken, filters, sorting]);

  const handlePageChange = (event, newPage) => {
    setFilters(prev => ({ ...prev, page: newPage }));
  };

  const fetchContractDetails = async (contractId) => {
    console.log('Fetching details for contract:', contractId);
    setLoadingContractId(contractId);
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/contracts/${contractId}`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'X-User-Email': userEmail,
            'X-Environment': filters.environment
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch contract details');
      }

      const data = await response.json();
      console.log('Contract details:', data);
      setSelectedContract(data);
      setDetailsDialogOpen(true);
    } catch (err) {
      console.error('Error fetching contract details:', err);
      setError(`Failed to fetch contract details: ${err.message}`);
    } finally {
      setLoadingContractId(null);
    }
  };

  const handleViewDetails = (contractId) => {
    fetchContractDetails(contractId);
  };

  const getStatusConfig = (status, contractKind) => {
    if (contractKind === 'education-provider-ep-financing-contract') {
      return {
        icon: <SchoolIcon />,
        color: 'secondary',
        label: `EP ${status}`
      };
    }

    const configs = {
      'VOIDED': {
        icon: <CancelIcon />,
        color: 'error',
        label: 'Voided'
      },
      'SIGNED': {
        icon: <CheckCircleIcon />,
        color: 'success',
        label: 'Signed'
      },
      'CREATED': {
        icon: <PendingIcon />,
        color: 'info',
        label: 'Created'
      },
      'AWAITING_SIGNATURE': {
        icon: <ScheduleIcon />,
        color: 'warning',
        label: 'Awaiting Signature'
      }
    };
    return configs[status] || { icon: <PendingIcon />, color: 'default', label: status };
  };

  const ContractDetailsDialog = () => {
    // Get contract type
    const getContractType = () => {
      if (!selectedContract) return null;
      
      if (selectedContract.contractKind === 'education-provider-ep-financing-contract') {
        return 'EP';
      }
      return selectedContract.title?.startsWith('ISA') ? 'ISA' : 'FixPay';
    };

    return (
      <Dialog
        open={detailsDialogOpen}
        onClose={() => setDetailsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            Contract Details
            {selectedContract && (
              <Chip 
                label={getContractType()}
                color="primary"
                size="small"
              />
            )}
          </Box>
          <IconButton onClick={() => setDetailsDialogOpen(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedContract ? (
            <ContractDetails contract={selectedContract} />
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const fetchEducationProviders = async () => {
    console.log('Fetching education providers for environment:', filters.environment);
    setLoadingProviders(true);
    
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/mydra/education-providers`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'X-User-Email': userEmail,
            'X-Environment': filters.environment
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch education providers');
      }

      const data = await response.json();
      console.log('Received education providers:', data);
      setEducationProviders(data.data || []);
    } catch (err) {
      console.error('Error fetching education providers:', err);
      setError(`Failed to fetch education providers: ${err.message}`);
    } finally {
      setLoadingProviders(false);
    }
  };

  useEffect(() => {
    if (authToken && userEmail) {
      fetchEducationProviders();
    }
  }, [filters.environment, authToken, userEmail]);

  const handleEnvironmentChange = (event) => {
    // Reset all filters except environment and pagination defaults
    setFilters({
      page: 1,
      limit: 10,
      environment: event.target.checked ? 'Production' : 'Staging',
      applicationId: '',
      educationProviderId: '',
      status: '',
      contractKind: ''
    });
  };

  // Add handler for sorting
  const handleSort = (field) => {
    setSorting(prev => ({
      sortBy: field,
      // If clicking the same field, toggle order, else default to desc
      sortOrder: (prev.sortBy === field && prev.sortOrder === 'desc') ? 'asc' : 'desc'
    }));
  };

  // Add helper function to format dates
  const formatDate = (dateString) => {
    if (!dateString) return '-';
    return new Date(dateString).toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  // Add copy handler
  const handleCopyId = (id) => {
    navigator.clipboard.writeText(id);
    setCopyFeedback(true);
  };

  // Helper function to get contract details
  const getContractDetails = (contract) => {
    const isISA = contract.envelopeData?.placeholder_fields;
    
    if (isISA) {
      // For ISA contracts
      const fields = contract.envelopeData.placeholder_fields;
      const schoolName = fields.find(f => f.api_key === 'SchoolName')?.value || '';
      const courseName = fields.find(f => f.api_key === 'CourseName')?.value || 'N/A';
      
      return {
        courseName: `${schoolName ? `${schoolName} - ` : ''}${courseName}`,
        courseCampus: fields.find(f => f.api_key === 'CourseCampus')?.value || 'No campus specified',
        cost: fields.find(f => f.api_key === 'ISATuitionFee')?.value || 'N/A',
        paymentInfo: `${fields.find(f => f.api_key === 'ISAIncomeSharePercentage')?.value || ''} Income Share`,
        studentName: fields.find(f => f.api_key === 'StudentName')?.value || 'No name',
        studentEmail: fields.find(f => f.api_key === 'StudentEmail')?.value || 'No email'
      };
    } else {
      // For FixPay contracts
      return {
        courseName: contract.envelopeData?.['Course: name with school name'] || 
                   contract.envelopeData?.['Course: name'] || 
                   'N/A',
        courseCampus: contract.envelopeData?.['Course: campus'] || 'No campus specified',
        cost: contract.envelopeData?.['Course: cost'] || 'N/A',
        paymentInfo: contract.envelopeData?.['FixPay: number of instalments'] ? 
                     `${contract.envelopeData['FixPay: number of instalments']} instalments` : 
                     'No instalment info',
        studentName: contract.envelopeData?.['Student: name'] || 'No name',
        studentEmail: contract.envelopeData?.['Student: email'] || 'No email'
      };
    }
  };

  // Define consistent column widths
  const columnWidths = {
    id: '80px',
    title: '200px',
    type: '150px',
    status: '130px',
    course: '250px',
    cost: '150px',
    student: '200px',
    lastUpdated: '150px',
    created: '150px',
    signedVoided: '150px',
    actions: '120px'
  };

  const TableSkeleton = () => (
    <TableBody>
      {[...Array(5)].map((_, index) => (
        <TableRow key={`skeleton-${index}`}>
          <TableCell sx={{ width: columnWidths.id }}>
            <Skeleton width="90%" />
          </TableCell>
          <TableCell sx={{ width: columnWidths.title }}>
            <Skeleton width="90%" />
          </TableCell>
          <TableCell sx={{ width: columnWidths.type }}>
            <Skeleton width="90%" />
          </TableCell>
          <TableCell sx={{ width: columnWidths.status }}>
            <Skeleton width="90%" />
          </TableCell>
          <TableCell sx={{ width: columnWidths.course }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.cost }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.student }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.lastUpdated }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.created }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.signedVoided }}>
            <Box>
              <Skeleton width="90%" />
              <Skeleton width="60%" />
            </Box>
          </TableCell>
          <TableCell sx={{ width: columnWidths.actions }}>
            <Skeleton width="90%" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Contracts Management
      </Typography>

      {/* Environment Toggle */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <Typography>Staging</Typography>
        <Switch
          checked={filters.environment === 'Production'}
          onChange={handleEnvironmentChange}
          color="primary"
        />
        <Typography>Production</Typography>
        {filters.environment === 'Production' && (
          <Alert severity="warning" sx={{ ml: 2 }}>
            Warning: You are in Production mode. Changes may impact live data.
          </Alert>
        )}
      </Box>

      {/* Filter controls - adjusted sizes */}
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <TextField
            fullWidth
            label="Application ID"
            value={filters.applicationId}
            onChange={(e) => setFilters(prev => ({ ...prev, applicationId: e.target.value, page: 1 }))}
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Contract Type</InputLabel>
            <Select
              value={filters.contractKind}
              onChange={(e) => setFilters(prev => ({ ...prev, contractKind: e.target.value, page: 1 }))}
              label="Contract Type"
            >
              {contractTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Education Provider</InputLabel>
            <Select
              value={filters.educationProviderId}
              onChange={(e) => setFilters(prev => ({ 
                ...prev, 
                educationProviderId: e.target.value,
                page: 1 
              }))}
              label="Education Provider"
            >
              <MenuItem value="">All Providers</MenuItem>
              {educationProviders
                .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by name
                .map((provider) => (
                  <MenuItem key={provider._id} value={provider._id}>
                    {provider.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <FormControl fullWidth size="small">
            <InputLabel>Status</InputLabel>
            <Select
              value={filters.status}
              onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value, page: 1 }))}
              label="Status"
            >
              <MenuItem value="">All Statuses</MenuItem>
              <MenuItem value="SIGNED">Signed</MenuItem>
              <MenuItem value="VOIDED">Voided</MenuItem>
              <MenuItem value="CREATED">Created</MenuItem>
              <MenuItem value="AWAITING_SIGNATURE">Awaiting Signature</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {/* Table Section */}
      {loading ? (
        <TableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: columnWidths.id }}>ID</TableCell>
                <TableCell sx={{ width: columnWidths.title }}>Title</TableCell>
                <TableCell sx={{ width: columnWidths.type }}>Type</TableCell>
                <TableCell sx={{ width: columnWidths.status }}>Status</TableCell>
                <TableCell sx={{ width: columnWidths.course }}>Course</TableCell>
                <TableCell sx={{ width: columnWidths.cost }}>Cost</TableCell>
                <TableCell sx={{ width: columnWidths.student }}>Student</TableCell>
                <TableCell 
                  sx={{ 
                    width: columnWidths.lastUpdated,
                    cursor: 'pointer',
                    backgroundColor: sorting.sortBy === 'updatedAt' ? 'action.hover' : 'inherit',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    }
                  }}
                  onClick={() => handleSort('updatedAt')}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    Last Updated
                    {sorting.sortBy === 'updatedAt' && (
                      <ArrowDropDown 
                        sx={{ 
                          transform: sorting.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }} 
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: columnWidths.created,
                    cursor: 'pointer',
                    backgroundColor: sorting.sortBy === 'createdAt' ? 'action.hover' : 'inherit',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    }
                  }}
                  onClick={() => handleSort('createdAt')}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    Created
                    {sorting.sortBy === 'createdAt' && (
                      <ArrowDropDown 
                        sx={{ 
                          transform: sorting.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }} 
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ width: columnWidths.signedVoided }}>Signed/Voided</TableCell>
                <TableCell sx={{ width: columnWidths.actions }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            
            <TableSkeleton />
          </StyledTable>
        </TableContainer>
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
      ) : (
        <TableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: columnWidths.id }}>ID</TableCell>
                <TableCell sx={{ width: columnWidths.title }}>Title</TableCell>
                <TableCell sx={{ width: columnWidths.type }}>Type</TableCell>
                <TableCell sx={{ width: columnWidths.status }}>Status</TableCell>
                <TableCell sx={{ width: columnWidths.course }}>Course</TableCell>
                <TableCell sx={{ width: columnWidths.cost }}>Cost</TableCell>
                <TableCell sx={{ width: columnWidths.student }}>Student</TableCell>
                <TableCell 
                  sx={{ 
                    width: columnWidths.lastUpdated,
                    cursor: 'pointer',
                    backgroundColor: sorting.sortBy === 'updatedAt' ? 'action.hover' : 'inherit',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    }
                  }}
                  onClick={() => handleSort('updatedAt')}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    Last Updated
                    {sorting.sortBy === 'updatedAt' && (
                      <ArrowDropDown 
                        sx={{ 
                          transform: sorting.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }} 
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: columnWidths.created,
                    cursor: 'pointer',
                    backgroundColor: sorting.sortBy === 'createdAt' ? 'action.hover' : 'inherit',
                    '&:hover': {
                      backgroundColor: 'action.hover',
                    }
                  }}
                  onClick={() => handleSort('createdAt')}
                >
                  <Box display="flex" alignItems="center" gap={0.5}>
                    Created
                    {sorting.sortBy === 'createdAt' && (
                      <ArrowDropDown 
                        sx={{ 
                          transform: sorting.sortOrder === 'asc' ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }} 
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell sx={{ width: columnWidths.signedVoided }}>Signed/Voided</TableCell>
                <TableCell sx={{ width: columnWidths.actions }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contracts.map((contract) => {
                const statusConfig = getStatusConfig(contract.status, contract.contractKind);
                const isLoading = loadingContractId === contract._id;
                const details = getContractDetails(contract);
                
                return (
                  <TableRow key={contract._id}>
                    <TableCell>
                      <Tooltip title={`ID: ${contract._id}`} arrow>
                        <IconButton 
                          size="small" 
                          onClick={() => handleCopyId(contract._id)}
                          sx={{ 
                            '&:hover': { 
                              backgroundColor: 'action.hover' 
                            }
                          }}
                        >
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{contract.title}</TableCell>
                    <TableCell>
                      <Chip
                        label={contract.contractKind === 'education-provider-ep-financing-contract' 
                          ? 'Education Provider' 
                          : 'Customer Financing'}
                        size="small"
                        color={contract.contractKind === 'education-provider-ep-financing-contract' 
                          ? 'secondary' 
                          : 'primary'}
                      />
                    </TableCell>
                    <TableCell>
                      <Chip
                        icon={statusConfig?.icon}
                        label={statusConfig?.label}
                        color={statusConfig?.color}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {details.courseName}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {details.courseCampus}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {details.cost}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {details.paymentInfo}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {details.studentName}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {details.studentEmail}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {formatDate(contract.updatedAt)}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {contract.updatedAt ? formatDistanceToNow(new Date(contract.updatedAt), { addSuffix: true }) : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">
                        {formatDate(contract.createdAt)}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {contract.createdAt ? formatDistanceToNow(new Date(contract.createdAt), { addSuffix: true }) : ''}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {contract.dateSigned && (
                        <Typography variant="body2">
                          Signed: {formatDate(contract.dateSigned)}
                        </Typography>
                      )}
                      {contract.dateVoided && (
                        <Typography variant="caption" color="error" display="block">
                          Voided: {formatDate(contract.dateVoided)}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleViewDetails(contract._id)}
                        disabled={isLoading}
                        sx={{ minWidth: 100 }}
                      >
                        {isLoading ? (
                          <>
                            <CircularProgress size={20} sx={{ mr: 1 }} />
                            Loading...
                          </>
                        ) : (
                          'View Details'
                        )}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>
        </TableContainer>
      )}

      {/* Add pagination only when we have contracts */}
      {!loading && contracts.length > 0 && (
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'center',
            p: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}
        >
          <Pagination
            count={totalPages}
            page={filters.page}
            onChange={handlePageChange}
            color="primary"
            size="small"
          />
        </Box>
      )}

      {/* Show a message when no results */}
      {!loading && contracts.length === 0 && (
        <Box sx={{ textAlign: 'center', py: 3 }}>
          <Typography color="text.secondary">
            No contracts found matching your criteria
          </Typography>
        </Box>
      )}

      <ContractDetailsDialog />

      {/* Add copy feedback Snackbar */}
      <Snackbar
        open={copyFeedback}
        autoHideDuration={2000}
        onClose={() => setCopyFeedback(false)}
        message="ID copied to clipboard"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      />
    </Box>
  );
}

export default MydraContractsPage; 