import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Alert,
  Snackbar,
  InputAdornment,
  Tooltip,
  Switch,
  CircularProgress
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { getAuth } from 'firebase/auth';
import { alpha } from '@mui/material/styles';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ISATermsManager = () => {
  const [terms, setTerms] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    cap: '',
    percentage: '',
    maturity: '',   
    maxPayments: '',
    minThreshold: '',
    status: 'active',
    availableCountries: [],
    description: '',
    financialProductSponsor: '',
    predictedIncome: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState([]);
  const [isProduction, setIsProduction] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorModal, setErrorModal] = useState({
    open: false,
    title: '',
    message: '',
    details: null
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    term: null
  });

  useEffect(() => {
    fetchTerms();
    fetchSponsors();
  }, [isProduction]);

  const fetchTerms = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      
      const response = await fetch(`${API_BASE_URL}/mydra/financial-terms`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Environment': isProduction ? 'Production' : 'Staging'
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch terms');
      
      const data = await response.json();
      console.log('Raw data:', data);
      
      const termsArray = data.data || data;
      console.log('Terms array:', termsArray);
      
      setTerms(Array.isArray(termsArray) ? termsArray : []);
    } catch (error) {
      showSnackbar('Error fetching terms: ' + error.message, 'error');
      setTerms([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchSponsors = async () => {
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      
      const response = await fetch(`${API_BASE_URL}/mydra/financial-product-sponsors`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Environment': isProduction ? 'Production' : 'Staging'
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch sponsors');
      
      const data = await response.json();
      console.log('Raw sponsors data:', data);
      
      const sponsorsArray = data.data || data;
      console.log('Sponsors array:', sponsorsArray);
      
      setSponsors(Array.isArray(sponsorsArray) ? sponsorsArray : []);
    } catch (error) {
      showSnackbar('Error fetching sponsors: ' + error.message, 'error');
    }
  };

  const handleSubmit = async () => {
    // Validate all required fields
    if (!formData.cap || isNaN(formData.cap)) {
      showSnackbar('Cap must be a valid number', 'error');
      return;
    }

    if (!formData.minThreshold || isNaN(formData.minThreshold)) {
      showSnackbar('Min Threshold must be a valid number', 'error');
      return;
    }

    if (!formData.percentage || isNaN(formData.percentage)) {
      showSnackbar('Percentage must be a valid number', 'error');
      return;
    }

    if (!formData.maturity || isNaN(formData.maturity)) {
      showSnackbar('Maturity must be a valid number', 'error');
      return;
    }

    if (!formData.maxPayments || isNaN(formData.maxPayments)) {
      showSnackbar('Max Payments must be a valid number', 'error');
      return;
    }

    if (!formData.predictedIncome || isNaN(formData.predictedIncome)) {
      showSnackbar('Predicted Income must be a valid number', 'error');
      return;
    }

    setIsSubmitting(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      
      const response = await fetch(`${API_BASE_URL}/mydra/financial-terms${selectedTerm ? `/${selectedTerm._id}` : ''}`, {
        method: selectedTerm ? 'PUT' : 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Environment': isProduction ? 'Production' : 'Staging',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...formData,
          financialProductSponsor: formData.financialProductSponsor || null,
        }),
      });

      const data = await response.json();
      console.log('Response:', data);

      if (!response.ok || data.error) {
        const errorMessage = data.message || data.error || 'Failed to save term';
        const error = new Error(errorMessage);
        error.response = data;
        throw error;
      }

      showSnackbar(`Term successfully ${selectedTerm ? 'updated' : 'created'}`, 'success');
      setOpenDialog(false);
      fetchTerms();
    } catch (error) {
      console.error('Error details:', error);
      
      setErrorModal({
        open: true,
        title: 'Error Saving Term',
        message: error.message,
        details: error.response || null
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (termId) => {
    setIsSubmitting(true);
    try {
      const auth = getAuth();
      const token = await auth.currentUser?.getIdToken();
      
      const response = await fetch(`${API_BASE_URL}/mydra/financial-terms/${termId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'X-Environment': isProduction ? 'Production' : 'Staging'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete term');
      }

      showSnackbar('Term successfully deleted', 'success');
      setDeleteConfirmation({ open: false, term: null });
      fetchTerms();
    } catch (error) {
      setErrorModal({
        open: true,
        title: 'Error Deleting Term',
        message: error.message,
        details: error.response || null
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const toggleEnvironment = () => {
    setIsProduction(!isProduction);
  };

  const formatCurrency = (amount, currency) => {
    // Convert currency symbol to code if necessary
    const currencyCode = currency === '€' ? 'EUR' : currency;

    if (amount === 0) {
      return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount / 100);
    }

    if (!amount || !currency) {
      return 'N/A';
    }
    
    try {
      // Attempt to format with Intl.NumberFormat
      return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: currencyCode,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(amount / 100);
    } catch (error) {
      console.error('Error formatting currency:', error);
      // Fallback formatting
      return `${(amount / 100).toFixed(2)} ${currency}`;
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography variant="body1">Staging</Typography>
        <Switch
          checked={isProduction}
          onChange={toggleEnvironment}
          inputProps={{ 'aria-label': 'Environment toggle' }}
        />
        <Typography variant="body1">Production</Typography>
      </Box>

      {isProduction && (
        <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
          Warning: You are in Production mode. Changes may impact all live terms.
        </Typography>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedTerm(null);
            setFormData({
              name: '',
              cap: '',
              percentage: '',
              maturity: '',
              maxPayments: '',
              minThreshold: '',
              status: 'active',
              availableCountries: [],
              description: '',
              financialProductSponsor: '',
              predictedIncome: ''
            });
            setOpenDialog(true);
          }}
        >
          Add New Term
        </Button>
        
        <Typography variant="body2" color="text.secondary">
          Showing {terms.length} of {terms.length} terms
        </Typography>
      </Box>

      <TableContainer 
        component={Paper} 
        sx={{ 
          boxShadow: 2,
          borderRadius: 2,
          overflow: 'hidden',
          width: '100%',
          '& .MuiTable-root': { 
            width: '100%',
            tableLayout: 'fixed'
          }
        }}
      >
        <Table 
          size="small" 
          sx={{ 
            '& .MuiTableCell-root': { 
              py: 1.5,
              px: 2,
            },
            minWidth: '100%'
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
                '& .MuiTableCell-root': {
                  color: 'primary.main',
                  fontWeight: 600,
                  fontSize: '0.875rem',
                }
              }}
            >
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Sponsor</TableCell>
              <TableCell align="right">Cap</TableCell>
              <TableCell align="right">Percentage</TableCell>
              <TableCell align="right">Min Threshold</TableCell>
              <TableCell align="right">Maturity</TableCell>
              <TableCell align="right">Max Payments</TableCell>
              <TableCell align="right">Predicted Monthly Income</TableCell>
              <TableCell>Countries</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={10} align="center">Loading...</TableCell>
              </TableRow>
            ) : terms.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10} align="center">No terms found</TableCell>
              </TableRow>
            ) : (
              terms.map((term) => (
                <TableRow 
                  key={term._id} 
                  hover
                  sx={{
                    '&:hover': {
                      backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.02),
                    },
                    '& .MuiTableCell-root': {
                      borderColor: (theme) => alpha(theme.palette.divider, 0.3),
                    }
                  }}
                >
                  <TableCell>
                    <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                      {term.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={term.description} placement="top-start">
                      <Typography 
                        variant="body2" 
                        sx={{ 
                          maxWidth: 300,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          lineHeight: '1.2em',
                          height: '2.4em',
                          color: 'text.secondary',
                          '&:hover': {
                            cursor: 'help',
                            color: 'text.primary',
                          }
                        }}
                      >
                        {term.description}
                      </Typography>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {sponsors.find(s => s._id === term.financialProductSponsor) ? (
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        gap: 1,
                        '& img': {
                          filter: 'grayscale(0.2)',
                          transition: 'filter 0.2s',
                          '&:hover': {
                            filter: 'grayscale(0)',
                          }
                        }
                      }}>
                        <img 
                          src={sponsors.find(s => s._id === term.financialProductSponsor).logoUrl} 
                          alt=""
                          style={{ 
                            height: 24,
                            width: 'auto',
                            objectFit: 'contain'
                          }} 
                        />
                        <Typography variant="body2">
                          {sponsors.find(s => s._id === term.financialProductSponsor).name}
                        </Typography>
                      </Box>
                    ) : (
                      'Unknown'
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" sx={{ fontWeight: 500, color: 'text.primary' }}>
                      {formatCurrency(term.cap, 'EUR')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {(term.percentage * 100).toFixed(1)}%
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2" sx={{ fontWeight: 500 }}>
                      {formatCurrency(term.minThreshold, 'EUR')}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">{term.maturity} months</TableCell>
                  <TableCell align="right">{term.maxPayments}</TableCell>
                  <TableCell align="right">
                    {term.predictedIncome ? formatCurrency(term.predictedIncome, 'EUR') : 'N/A'}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ 
                      display: 'flex', 
                      gap: 0.5,
                      '& .MuiChip-root': {
                        borderRadius: 1,
                      }
                    }}>
                      {term.availableCountries?.map((country) => (
                        <Chip
                          key={country}
                          label={country}
                          size="small"
                          variant="outlined"
                          sx={{
                            borderColor: (theme) => alpha(theme.palette.primary.main, 0.3),
                            color: 'primary.main',
                            fontWeight: 500,
                          }}
                        />
                      )) || 'No countries'}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Chip 
                      label={term.status} 
                      color={term.status === 'active' ? 'success' : 'default'}
                      size="small"
                      sx={{
                        fontWeight: 500,
                        borderRadius: 1,
                        backgroundColor: term.status === 'active' 
                          ? (theme) => alpha(theme.palette.success.main, 0.1)
                          : (theme) => alpha(theme.palette.grey[500], 0.1),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      size="small"
                      sx={{ 
                        color: 'error.main',
                        '&:hover': {
                          backgroundColor: (theme) => alpha(theme.palette.error.main, 0.1),
                        }
                      }}
                      onClick={() => setDeleteConfirmation({ open: true, term })}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle>
          {selectedTerm ? 'Edit Term' : 'Add New Term'}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
            {/* Basic Information */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500, color: 'primary.main' }}>
                Basic Information
              </Typography>
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <FormControl fullWidth>
                  <InputLabel>Financial Product Sponsor</InputLabel>
                  <Select
                    value={formData.financialProductSponsor || ''}
                    label="Financial Product Sponsor"
                    onChange={(e) => setFormData({ ...formData, financialProductSponsor: e.target.value })}
                  >
                    {sponsors.map((sponsor) => (
                      <MenuItem key={sponsor._id} value={sponsor._id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <img 
                            src={sponsor.logoUrl} 
                            alt=""
                            style={{ height: 24, width: 'auto', objectFit: 'contain' }} 
                          />
                          {sponsor.name}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Name"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
              </Box>
            </Box>

            {/* Description */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500, color: 'primary.main' }}>
                Description
              </Typography>
              <TextField
                fullWidth
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                multiline
                rows={3}
              />
            </Box>

            {/* Financial Terms */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500, color: 'primary.main' }}>
                Financial Terms
              </Typography>
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField
                  fullWidth
                  required
                  label="Cap"
                  type="number"
                  value={formData.cap ? formData.cap / 100 : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, cap: value === '' ? '' : value * 100 });
                    }
                  }}
                  error={formData.cap === '' || isNaN(formData.cap)}
                  helperText={formData.cap === '' || isNaN(formData.cap) ? 'Cap is required and must be a number' : ''}
                />
                <TextField
                  fullWidth
                  required
                  label="Min Threshold"
                  type="number"
                  value={formData.minThreshold ? formData.minThreshold / 100 : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, minThreshold: value === '' ? '' : value * 100 });
                    }
                  }}
                  error={formData.minThreshold === '' || isNaN(formData.minThreshold)}
                  helperText={formData.minThreshold === '' || isNaN(formData.minThreshold) ? 'Min Threshold is required and must be a number' : ''}
                />
                <TextField
                  fullWidth
                  required
                  label="Percentage"
                  type="number"
                  value={formData.percentage}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, percentage: value });
                    }
                  }}
                  error={formData.percentage === '' || isNaN(formData.percentage)}
                  helperText={formData.percentage === '' || isNaN(formData.percentage) ? 'Percentage is required and must be a number' : ''}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                  }}
                />
                <TextField
                  fullWidth
                  required
                  label="Predicted Monthly Income"
                  type="number"
                  value={formData.predictedIncome ? formData.predictedIncome / 100 : ''}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, predictedIncome: value === '' ? '' : value * 100 });
                    }
                  }}
                  error={formData.predictedIncome === '' || isNaN(formData.predictedIncome)}
                  helperText={formData.predictedIncome === '' || isNaN(formData.predictedIncome) ? 'Predicted Income is required and must be a number' : ''}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                />
              </Box>
            </Box>

            {/* Payment Terms */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500, color: 'primary.main' }}>
                Payment Terms
              </Typography>
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                <TextField
                  fullWidth
                  required
                  label="Maturity (months)"
                  type="number"
                  value={formData.maturity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, maturity: value });
                    }
                  }}
                  error={formData.maturity === '' || isNaN(formData.maturity)}
                  helperText={formData.maturity === '' || isNaN(formData.maturity) ? 'Maturity is required and must be a number' : ''}
                />
                <TextField
                  fullWidth
                  required
                  label="Max Payments"
                  type="number"
                  value={formData.maxPayments}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === '' || !isNaN(value)) {
                      setFormData({ ...formData, maxPayments: value });
                    }
                  }}
                  error={formData.maxPayments === '' || isNaN(formData.maxPayments)}
                  helperText={formData.maxPayments === '' || isNaN(formData.maxPayments) ? 'Max Payments is required and must be a number' : ''}
                />
              </Box>
            </Box>

            {/* Status */}
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500, color: 'primary.main' }}>
                Status
              </Typography>
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={formData.status}
                  label="Status"
                  onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button 
            onClick={handleSubmit} 
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isSubmitting ? (selectedTerm ? 'Updating...' : 'Creating...') : (selectedTerm ? 'Update' : 'Create')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={errorModal.open} 
        onClose={() => setErrorModal({ ...errorModal, open: false })}
        maxWidth="md"
      >
        <DialogTitle sx={{ color: 'error.main' }}>
          {errorModal.title}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Error Message:
            </Typography>
            <Typography color="error" paragraph>
              {errorModal.message}
            </Typography>
            
            {errorModal.details && (
              <>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Technical Details:
                </Typography>
                <Paper 
                  sx={{ 
                    p: 2, 
                    bgcolor: 'grey.100',
                    maxHeight: 200,
                    overflow: 'auto'
                  }}
                >
                  <pre style={{ margin: 0 }}>
                    {JSON.stringify(errorModal.details, null, 2)}
                  </pre>
                </Paper>
              </>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setErrorModal({ ...errorModal, open: false })}
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={deleteConfirmation.open} 
        onClose={() => setDeleteConfirmation({ open: false, term: null })}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete the term "{deleteConfirmation.term?.name}"? 
            This action cannot be undone.
          </Typography>
          {isProduction && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Warning: You are deleting a term in Production environment!
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteConfirmation({ open: false, term: null })}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button 
            onClick={() => handleDelete(deleteConfirmation.term?._id)}
            variant="contained"
            color="error"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
          >
            {isSubmitting ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ISATermsManager;
