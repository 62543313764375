import { useQueryClient } from '@tanstack/react-query';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';

const EnvironmentContext = createContext({
    environment: 'Production',
    setEnvironment: () => {},
});

export function EnvironmentProvider({ children }) {
    const queryClient = useQueryClient();
    const [environment, _setEnvironment] = useState('Production');

    const setEnvironment = useCallback((environment) => {
        _setEnvironment(environment);
    }, []);

    useEffect(() => {
        (() => {
            queryClient.invalidateQueries();
        })();
    }, [environment, queryClient]);

    return (
        <EnvironmentContext.Provider value={{ environment, setEnvironment }}>
            {children}
        </EnvironmentContext.Provider>
    );
}

export const useEnvironment = () => {
    const context = useContext(EnvironmentContext);
    if (context === undefined) {
        throw new Error(
            'useEnvironment must be used within an EnvironmentProvider',
        );
    }
    return context;
};
