import React from 'react';
import { Box, Typography } from '@mui/material';
import ISATermsManager from '../../components_mydra/ISATermsManager';

const ISAPage = () => {
  return (
    <Box sx={{ width: '100%', p: 3 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        ISA Terms Management
      </Typography>
      <ISATermsManager />
    </Box>
  );
};

export default ISAPage;
