import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Paper,
  Grid,
  Button,
  Divider,
  IconButton,
  Collapse,
  InputAdornment,
  Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const ResultsDisplay = ({ results, inputs }) => (
  <Grid container spacing={3} sx={{ mb: 4 }}>
    <Grid item xs={12} md={6}>
      <Paper sx={{ 
        p: 3, 
        height: '100%',
        bgcolor: '#F8F9FE',
        border: '1px solid',
        borderColor: 'grey.200',
        '& > div': { mb: 2 }
      }}>
        <Typography variant="h6" sx={{ mb: 3, color: '#4355B9' }}>Company Profile</Typography>
        <Box sx={{ '& > div': { mb: 1.5 } }}>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Company Size:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>{results.companySize}</Typography>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Total Payroll:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>
              €{results.calculatedPayroll.toLocaleString()}
              {inputs.useAverageSalary && (
                <Typography component="span" sx={{ color: 'text.secondary', ml: 1, fontSize: '0.875rem' }}>
                  (from average salary)
                </Typography>
              )}
            </Typography>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Fundae Contribution:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>€{results.fundaeContribution.toLocaleString()}</Typography>
            <Tooltip
              title={
                <Box sx={{ p: 0.5, fontSize: '0.75rem' }}>
                  <Typography variant="caption" sx={{ display: 'block' }}>
                    Fundae contribution is calculated as 0.7% of total payroll
                  </Typography>
                </Box>
              }
              arrow
              placement="right"
            >
              <IconButton 
                size="small" 
                sx={{ 
                  ml: 0.5,
                  p: 0.5,
                  color: 'primary.main',
                  '&:hover': { 
                    bgcolor: 'primary.50' 
                  }
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Credit Percentage:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>{results.creditPercentage}%</Typography>
            <Tooltip
              title={
                <Box sx={{ p: 0.5, fontSize: '0.75rem' }}>
                  <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                    Credit percentage is determined by company size:
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>• 1-9 employees: 100%</Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>• 10-49 employees: 75%</Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>• 50-249 employees: 60%</Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>• 250+ employees: 50%</Typography>
                </Box>
              }
              arrow
              placement="right"
            >
              <IconButton 
                size="small" 
                sx={{ 
                  ml: 0.5,
                  p: 0.5,
                  color: 'primary.main',
                  '&:hover': { 
                    bgcolor: 'primary.50' 
                  }
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Total Fundae Credit:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>€{results.fundaeCredit.toLocaleString()}</Typography>
            <Tooltip
              title={
                <Box sx={{ p: 0.5, fontSize: '0.75rem' }}>
                  <Typography variant="caption" sx={{ display: 'block', mb: 0.5 }}>
                    Annual credit available for the company. Calculated as:
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block' }}>
                    Fundae Contribution × Credit Percentage
                  </Typography>
                  <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                    Note: Minimum €420 guaranteed for companies with less than 10 employees
                  </Typography>
                </Box>
              }
              arrow
              placement="right"
            >
              <IconButton 
                size="small" 
                sx={{ 
                  ml: 0.5,
                  p: 0.5,
                  color: 'primary.main',
                  '&:hover': { 
                    bgcolor: 'primary.50' 
                  }
                }}
              >
                <InfoOutlinedIcon sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Total Learning Budget Available:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>€{results.totalLearningBudget.toLocaleString()}</Typography>
          </Box>
        </Box>
      </Paper>
    </Grid>
    
    <Grid item xs={12} md={6}>
      <Paper sx={{ 
        p: 3, 
        height: '100%',
        bgcolor: '#EEFBF4',
        border: '1px solid',
        borderColor: '#34A853',
        '& > div': { mb: 2 }
      }}>
        <Typography variant="h6" sx={{ mb: 3, color: '#1E8E3E' }}>Budget Summary</Typography>
        <Box sx={{ '& > div': { mb: 1.5 } }}>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Total Employees:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>
              {inputs.employeeCount}
            </Typography>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Employees for Training with Company Learning Budget:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>
              {results.employeesForTraining}
              <Typography component="span" sx={{ color: 'text.secondary', ml: 1, fontSize: '0.875rem' }}>
                ({Math.round((results.employeesForTraining / inputs.employeeCount) * 100)}% of workforce)
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Fundae Allocation:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>
              €{results.fundaeAllocation.toLocaleString()}
              <Typography component="span" sx={{ color: 'text.secondary', ml: 1, fontSize: '0.875rem' }}>
                ({results.fundaeRate}% of credit)
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography component="span" sx={{ fontWeight: 'medium' }}>Company Budget:</Typography>
            <Typography component="span" sx={{ ml: 1 }}>
              €{results.companyLearningBudget.toLocaleString()}
              <Typography component="span" sx={{ color: 'text.secondary', ml: 1, fontSize: '0.875rem' }}>
                (€{results.budgetPerEmployee.toLocaleString()} per employee)
              </Typography>
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box>
            <Typography component="span" sx={{ fontWeight: 'bold' }}>Total Budget:</Typography>
            <Typography component="span" sx={{ ml: 1, color: 'success.dark', fontWeight: 'bold' }}>
              €{results.totalTrainingBudget.toLocaleString()}
            </Typography>
          </Box>
        </Box>
        
        {results.averagePerEmployee < 1000 && (
          <Paper sx={{ 
            mt: 2, 
            p: 2.5,
            bgcolor: '#FFF3E0',
            border: '1px solid',
            borderColor: '#FB8C00',
            borderRadius: 1
          }}>
            <Typography 
              variant="body1" 
              sx={{ 
                color: '#B65D0E',
                fontWeight: 'medium',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}
            >
              <strong>Recommendation:</strong> Consider our FixPay option
            </Typography>
          </Paper>
        )}
      </Paper>
    </Grid>
  </Grid>
);

const SettingsPanel = ({ settings, handleSettingChange }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Box sx={{ mt: 4 }}>
      <Button
        onClick={() => setExpanded(!expanded)}
        endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        sx={{ 
          mb: 2,
          color: 'primary.main',
          '&:hover': {
            bgcolor: 'primary.50'
          }
        }}
      >
        {expanded ? "Hide Advanced Settings" : "Show Advanced Settings"}
      </Button>
      
      <Collapse in={expanded}>
        <Paper sx={{ 
          p: 3, 
          bgcolor: '#F8F9FE',
          border: '1px solid',
          borderColor: 'grey.200'
        }}>
          <Grid container spacing={3}>
            {/* Header Row */}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={3}><Typography variant="subtitle2">Company Size</Typography></Grid>
                <Grid item xs={3}><Typography variant="subtitle2">Max Employees</Typography></Grid>
                <Grid item xs={2}><Typography variant="subtitle2">Training %</Typography></Grid>
                <Grid item xs={2}><Typography variant="subtitle2">Fundae Rate</Typography></Grid>
                <Grid item xs={2}><Typography variant="subtitle2">Budget/Employee</Typography></Grid>
              </Grid>
            </Grid>

            {/* Small Company Row */}
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}><Typography>Small</Typography></Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    name="smallCompanyMaxEmployees"
                    value={settings.smallCompanyMaxEmployees}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="smallCompanyTrainingRate"
                    value={settings.smallCompanyTrainingRate * 100}
                    onChange={(e) => handleSettingChange({
                      target: {
                        name: e.target.name,
                        value: parseFloat(e.target.value) / 100
                      }
                    })}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="smallCompanyFundaeRate"
                    value={settings.smallCompanyFundaeRate}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="smallCompanyBudgetPerEmployee"
                    value={settings.smallCompanyBudgetPerEmployee}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Medium Company Row */}
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}><Typography>Medium</Typography></Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    type="number"
                    name="mediumCompanyMaxEmployees"
                    value={settings.mediumCompanyMaxEmployees}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="mediumCompanyTrainingRate"
                    value={settings.mediumCompanyTrainingRate * 100}
                    onChange={(e) => handleSettingChange({
                      target: {
                        name: e.target.name,
                        value: parseFloat(e.target.value) / 100
                      }
                    })}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="mediumCompanyFundaeRate"
                    value={settings.mediumCompanyFundaeRate}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="mediumCompanyBudgetPerEmployee"
                    value={settings.mediumCompanyBudgetPerEmployee}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Large Company Row */}
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}><Typography>Large</Typography></Grid>
                <Grid item xs={3}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                    Above {settings.mediumCompanyMaxEmployees}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="largeCompanyTrainingRate"
                    value={settings.largeCompanyTrainingRate * 100}
                    onChange={(e) => handleSettingChange({
                      target: {
                        name: e.target.name,
                        value: parseFloat(e.target.value) / 100
                      }
                    })}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="largeCompanyFundaeRate"
                    value={settings.largeCompanyFundaeRate}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    type="number"
                    name="largeCompanyBudgetPerEmployee"
                    value={settings.largeCompanyBudgetPerEmployee}
                    onChange={handleSettingChange}
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
    </Box>
  );
};

const FundingCalculator = () => {
  const [settings, setSettings] = useState({
    smallCompanyMaxEmployees: 50,
    mediumCompanyMaxEmployees: 500,
    smallCompanyFundaeRate: 100,
    mediumCompanyFundaeRate: 100,
    largeCompanyFundaeRate: 100,
    smallCompanyTrainingRate: 0.35,
    mediumCompanyTrainingRate: 0.20,
    largeCompanyTrainingRate: 0.10,
    smallCompanyBudgetPerEmployee: 1000,
    mediumCompanyBudgetPerEmployee: 1500,
    largeCompanyBudgetPerEmployee: 2000
  });
  const [inputs, setInputs] = useState({
    employeeCount: '',
    payrollExpense: '',
    averageSalary: '',
    useAverageSalary: false
  });
  const [results, setResults] = useState({
    companySize: '',
    fundaeContribution: 0,
    creditPercentage: 0,
    fundaeCredit: 0,
    fundaeAllocation: 0,
    employeesForTraining: 0,
    companyLearningBudget: 0,
    totalTrainingBudget: 0,
    averagePerEmployee: 0,
    calculatedPayroll: 0,
    budgetPerEmployee: 0
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'averageSalary' || name === 'payrollExpense') {
      // First remove any existing formatting (dots, commas, spaces)
      const cleanValue = value.replace(/[^\d]/g, '');
      
      // Convert to number for validation
      const numericValue = Number(cleanValue);
      
      // Format for display with thousands separator
      const formattedValue = numericValue.toLocaleString('en-US', {
        maximumFractionDigits: 0,
        useGrouping: true
      });

      setInputs(prev => ({
        ...prev,
        [name]: formattedValue,
        // Store the clean numeric value for calculations
        [`${name}Numeric`]: numericValue
      }));
    } else {
      setInputs(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    setInputs(prev => ({
      ...prev,
      useAverageSalary: e.target.checked
    }));
  };

  const handleSettingChange = (e) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value === '' ? '' : Number(value)
    }));
  };

  const calculateResults = () => {
    if (!inputs.employeeCount || inputs.employeeCount <= 0) return;
    
    const employeeCount = parseInt(inputs.employeeCount);
    let payrollExpense = 0;
    
    // Use the stored numeric values for calculations
    if (inputs.useAverageSalary && inputs.averageSalaryNumeric) {
      payrollExpense = employeeCount * inputs.averageSalaryNumeric;
    } else if (!inputs.useAverageSalary && inputs.payrollExpenseNumeric) {
      payrollExpense = inputs.payrollExpenseNumeric;
    } else {
      return;
    }

    // First determine company size based on our tiers
    let companySize;
    let trainingRate;
    let budgetPerEmployee;
    let fundaeRate;

    if (employeeCount <= settings.smallCompanyMaxEmployees) {
      companySize = "Small";
      trainingRate = settings.smallCompanyTrainingRate;
      budgetPerEmployee = settings.smallCompanyBudgetPerEmployee;
      fundaeRate = settings.smallCompanyFundaeRate;
    } else if (employeeCount <= settings.mediumCompanyMaxEmployees) {
      companySize = "Medium";
      trainingRate = settings.mediumCompanyTrainingRate;
      budgetPerEmployee = settings.mediumCompanyBudgetPerEmployee;
      fundaeRate = settings.mediumCompanyFundaeRate;
    } else {
      companySize = "Large";
      trainingRate = settings.largeCompanyTrainingRate;
      budgetPerEmployee = settings.largeCompanyBudgetPerEmployee;
      fundaeRate = settings.largeCompanyFundaeRate;
    }

    // Determine credit percentage based on Fundae rules (separate from company size tiers)
    let creditPercentage;
    if (employeeCount <= 9) {
      creditPercentage = 100;
    } else if (employeeCount <= 49) {
      creditPercentage = 75;
    } else if (employeeCount <= 249) {
      creditPercentage = 60;
    } else {
      creditPercentage = 50;
    }

    // Calculate FUNDAE contribution (0.7% of payroll)
    const fundaeContribution = payrollExpense * 0.007;

    // Calculate Fundae Credit (with minimum guarantee for micro companies)
    const fundaeCredit = employeeCount <= 9 
      ? Math.max(fundaeContribution * (creditPercentage / 100), 420)
      : fundaeContribution * (creditPercentage / 100);

    // Calculate total learning budget available (based on total employees)
    const totalLearningBudget = employeeCount * budgetPerEmployee;
    
    // Calculate number of employees for training
    const employeesForTraining = Math.round(employeeCount * trainingRate);
    
    // Calculate company budget (based on employees for training)
    const companyBudget = employeesForTraining * budgetPerEmployee;
    
    // Calculate total training budget
    const fundaeAllocation = fundaeCredit * (fundaeRate / 100);
    const totalTrainingBudget = fundaeAllocation + companyBudget;
    const averagePerEmployee = employeesForTraining > 0 
      ? totalTrainingBudget / employeesForTraining 
      : 0;

    setResults({
      companySize,
      calculatedPayroll: Number(payrollExpense.toFixed(2)),
      fundaeContribution: Number(fundaeContribution.toFixed(2)),
      creditPercentage,
      fundaeCredit: Number(fundaeCredit.toFixed(2)),
      fundaeAllocation: Number(fundaeAllocation.toFixed(2)),
      employeesForTraining,
      budgetPerEmployee,
      companyLearningBudget: Number(companyBudget.toFixed(2)),
      totalTrainingBudget: Number(totalTrainingBudget.toFixed(2)),
      averagePerEmployee: Number(averagePerEmployee.toFixed(2)),
      totalLearningBudget: Number(totalLearningBudget.toFixed(2)),
      fundaeRate
    });
  };

  // Recalculate whenever inputs OR settings change
  useEffect(() => {
    calculateResults();
  }, [
    inputs.employeeCount,
    inputs.payrollExpense,
    inputs.averageSalary,
    inputs.useAverageSalary,
    settings.smallCompanyMaxEmployees,
    settings.mediumCompanyMaxEmployees,
    settings.smallCompanyFundaeRate,
    settings.mediumCompanyFundaeRate,
    settings.largeCompanyFundaeRate,
    settings.smallCompanyTrainingRate,
    settings.mediumCompanyTrainingRate,
    settings.largeCompanyTrainingRate,
    settings.smallCompanyBudgetPerEmployee,
    settings.mediumCompanyBudgetPerEmployee,
    settings.largeCompanyBudgetPerEmployee
  ]);

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto' }}>
      <Paper 
        sx={{ 
          p: 3, 
          mb: 4, 
          bgcolor: '#F8F9FE',
          '& .MuiInputBase-root': {
            bgcolor: 'white'
          },
          border: '1px solid',
          borderColor: 'primary.100'
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, color: '#4355B9' }}>
          Company Information
        </Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Number of Employees"
              type="number"
              name="employeeCount"
              value={inputs.employeeCount}
              onChange={handleInputChange}
              variant="outlined"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={inputs.useAverageSalary}
                  onChange={handleCheckboxChange}
                  sx={{ color: 'primary.main' }}
                />
              }
              label="Use average salary instead of total payroll"
              sx={{ 
                mt: 2,
                color: 'primary.dark',
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.875rem'
                }
              }}
            />
          </Grid>
          
          <Grid item xs={12} md={6}>
            {inputs.useAverageSalary ? (
              <TextField
                fullWidth
                label="Average Annual Salary (€)"
                type="text"
                name="averageSalary"
                value={inputs.averageSalary}
                onChange={handleInputChange}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
              />
            ) : (
              <TextField
                fullWidth
                label="Total Annual Payroll (€)"
                type="text"
                name="payrollExpense"
                value={inputs.payrollExpense}
                onChange={handleInputChange}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">€</InputAdornment>
                }}
              />
            )}
          </Grid>
        </Grid>
      </Paper>

      <SettingsPanel 
        settings={settings} 
        handleSettingChange={handleSettingChange} 
      />

      {results.companySize && (
        <Box sx={{ mt: 4 }}>
          <ResultsDisplay 
            results={results} 
            inputs={inputs} 
          />
        </Box>
      )}
    </Box>
  );
};

export default FundingCalculator; 