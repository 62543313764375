import {
    Button,
    CircularProgress,
    Stack,
    TableCell,
    TableRow,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { UserDocumentStatusChip } from './UserDocumentStatusChip';
import { CheckCircle, Close, Visibility } from '@mui/icons-material';
import { USER_DOCUMENT_STATUS } from './MydraUserScoringDocumentsTable.constants';
import { useEffect, useState } from 'react';
import { useMydraDocuments } from '../../../../hooks/data-hooks/useMydraDocuments';

export function UserDocumentRow({
    item,
    onDocumentReview,
    documentsToUpdate,
    actionsDisabled,
    ...props
}) {
    const [mydraDocumentIdsToDownload, setMydraDocumentIdsToDownload] =
        useState([]);

    const mydraDocumentQueries = useMydraDocuments(mydraDocumentIdsToDownload, {
        enabled: !!mydraDocumentIdsToDownload.length,
    });

    const isLoadingDocuments = mydraDocumentQueries.some(
        (query) => query.isLoading,
    );
    const allQueriesLoaded = mydraDocumentQueries.every(
        (query) => query.status === 'success',
    );

    const viewRelationships = async (relationships) => {
        const documentIds = relationships
            .filter((rel) => rel.documentType === 'Document')
            .map((r) => r.document._id);

        if (documentIds.length) {
            setMydraDocumentIdsToDownload(documentIds);
        }

        const onFidoChecks = relationships.filter(
            (rel) => rel.documentType === 'KycCheck',
        );

        onFidoChecks.forEach((check) => {
            window.open(check.document.resultsUri, '_blank');
        });
    };

    useEffect(() => {
        if (
            !mydraDocumentQueries.length ||
            isLoadingDocuments ||
            !allQueriesLoaded
        ) {
            return;
        }

        for (const query of mydraDocumentQueries) {
            if (query.data?.url) {
                const a = document.createElement('a');
                a.href = query.data.url;
                a.target = '_blank';
                a.download = query.data.url;

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        }

        setMydraDocumentIdsToDownload([]);
    }, [mydraDocumentQueries, isLoadingDocuments, allQueriesLoaded]);

    return (
        <TableRow {...props}>
            <TableCell sx={{ textTransform: 'uppercase' }}>
                {item.type}
            </TableCell>
            <TableCell sx={{ textTransform: 'uppercase' }}>
                <UserDocumentStatusChip status={item.status} />
            </TableCell>
            <TableCell>
                {item.expirationDate ? (
                    <Typography variant="caption">
                        {new Date(item.expirationDate).toLocaleString()}
                    </Typography>
                ) : (
                    <Typography variant="caption">&mdash;</Typography>
                )}
            </TableCell>
            <TableCell>
                {item.updatedAt ? (
                    <Typography variant="caption">
                        {new Date(item.updatedAt).toLocaleString()}
                    </Typography>
                ) : (
                    <Typography variant="caption">&mdash;</Typography>
                )}
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={1}>
                    <Button
                        disabled={
                            isLoadingDocuments ||
                            !Array.isArray(item.relationships) ||
                            !item.relationships.length
                        }
                        startIcon={
                            isLoadingDocuments ? (
                                <CircularProgress size={16} />
                            ) : (
                                <Visibility />
                            )
                        }
                        onClick={() => viewRelationships(item.relationships)}
                    >
                        View contents
                    </Button>

                    <ToggleButtonGroup
                        disabled={!!actionsDisabled}
                        size="small"
                        value={
                            documentsToUpdate.find((d) => d._id === item._id)
                                ?.status ?? null
                        }
                        exclusive
                        onChange={(event, newStatus) => {
                            onDocumentReview(item, newStatus);
                        }}
                        aria-label="text alignment"
                        sx={{
                            marginLeft: 'auto !important',
                        }}
                    >
                        <ToggleButton
                            value={USER_DOCUMENT_STATUS.Validated}
                            disabled={
                                item.status === USER_DOCUMENT_STATUS.Validated
                            }
                            aria-label="left aligned"
                            sx={{
                                '&.Mui-selected': {
                                    color: 'success.main',
                                },
                            }}
                        >
                            <CheckCircle sx={{ marginRight: 1 }} />
                            Validate
                        </ToggleButton>
                        <ToggleButton
                            value={USER_DOCUMENT_STATUS.Rejected}
                            disabled={
                                item.status === USER_DOCUMENT_STATUS.Rejected
                            }
                            aria-label="centered"
                            sx={{
                                '&.Mui-selected': {
                                    color: 'error.main',
                                },
                            }}
                        >
                            <Close />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </TableCell>
        </TableRow>
    );
}
