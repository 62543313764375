import React, { useState, useEffect, useMemo } from 'react';
import {
    Box, Typography, CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Button, TextField,
    Dialog, DialogTitle, DialogContent, DialogActions, Switch,
    Container, Snackbar, Alert, Collapse, IconButton, Link, Chip, Stack, Pagination, FormControl, Select, MenuItem
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material/styles';
import LabelIcon from '@mui/icons-material/Label';


import { getAuth, onAuthStateChanged } from 'firebase/auth';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '25%',
    maxWidth: '25%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const TagSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

const FixedWidthCell = styled(TableCell)(({ theme }) => ({
    width: '25%',
    maxWidth: '25%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const MydraTagsPage = () => {
    const [authToken, setAuthToken] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [newTagName, setNewTagName] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isProduction, setIsProduction] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [expandedTag, setExpandedTag] = useState(null);
    const [isCreatingTag, setIsCreatingTag] = useState(false);
    const [coursesPage, setCoursesPage] = useState(1);
    const [coursesPerPage, setCoursesPerPage] = useState(10);
    const [pagesState, setPagesState] = useState({});
    const [expandedTagId, setExpandedTagId] = useState(null);
    const [isLoadingCourses, setIsLoadingCourses] = useState(false);
    const [tagCourses, setTagCourses] = useState({});
    const [expandedRows, setExpandedRows] = useState({});
    const [loadingTags, setLoadingTags] = useState({});

    const sortedTags = useMemo(() => {
        return [...tags].sort((a, b) => {
            const aCount = tagCourses[a.slug]?.pagination?.totalItems || 0;
            const bCount = tagCourses[b.slug]?.pagination?.totalItems || 0;
            return bCount - aCount;
        });
    }, [tags, tagCourses]);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken) {    
            fetchTags();
        }
    }, [authToken, isProduction]);

    const fetchTags = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (response.ok) {
                const data = await response.json();
                const tagsWithCourses = data.map(tag => ({
                    ...tag,
                    courses: tag.courses || []
                }));
                setTags(tagsWithCourses);
            } else {
                throw new Error("Failed to fetch tags");
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
            setError("Failed to load tags. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchTagCourses = async (tagSlug, page = 1, limit = coursesPerPage) => {
        try {
            setLoadingTags(prev => ({ ...prev, [tagSlug]: true }));
            const response = await fetch(
                `${API_BASE_URL}/mydra/tags/${tagSlug}/courses?page=${page}&limit=${limit}`,
                {
                    headers: {
                        'Authorization': `Bearer ${authToken}`,
                        'X-Environment': isProduction ? 'Production' : 'Staging',
                    },
                }
            );
            const data = await response.json();
            
            if (response.ok) {
                // Use a Promise to ensure state updates happen in order
                await new Promise(resolve => {
                    setTagCourses(prev => {
                        const newState = {
                            ...prev,
                            [tagSlug]: {
                                courses: data.data,
                                pagination: data.pagination
                            }
                        };
                        resolve(newState);
                        return newState;
                    });
                });

                setPagesState(prev => ({
                    ...prev,
                    [tagSlug]: page
                }));
            } else {
                throw new Error(data.error || `Failed to fetch courses for tag ${tagSlug}`);
            }
        } catch (error) {
            console.error(`Error fetching courses for tag ${tagSlug}:`, error);
            showSnackbar(`Failed to fetch courses for tag ${tagSlug}. ${error.message}`, 'error');
        } finally {
            // Only set loading to false after we're sure the course data is updated
            setTimeout(() => {
                setLoadingTags(prev => ({ ...prev, [tagSlug]: false }));
            }, 100);
        }
    };

    const handleCreateTag = async () => {
        setIsCreatingTag(true);

        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newTagName }),
            });
            if (response.ok) {
                setNewTagName('');
                setOpenDialog(false);
                fetchTags();
                showSnackbar('Tag created successfully', 'success');
            } else {
                throw new Error("Failed to create tag");
            }
        } catch (error) {
            console.error("Error creating tag:", error);
            showSnackbar("Failed to create tag. Please try again.", 'error');
        } finally {
            setIsCreatingTag(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleExpandTag = (tagSlug) => {
        setExpandedTag(expandedTag === tagSlug ? null : tagSlug);
    };

    const getCourseUrl = (courseId) => {
        const env = isProduction ? '' : 'staging.';
        const hubUrl = `https://www.mydrahub.com/mydra/course/${courseId}?env=${isProduction ? 'Production' : 'Staging'}`;
        const marketplaceUrl = `https://marketplace.${env}mydra.io/en/courses/${courseId}`;
        return { hubUrl, marketplaceUrl };
    };

    const handlePageChange = (tagSlug, newPage) => {
        setPagesState(prev => ({
            ...prev,
            [tagSlug]: newPage
        }));
        fetchTagCourses(tagSlug, newPage);
    };

    const getAssociatedCoursesCount = (tag) => {
        if (tagCourses[tag.slug]?.pagination) {
            return tagCourses[tag.slug].pagination.totalItems;
        }
        return tag.courses?.length || 0;
    };

    const handleExpandClick = async (tagSlug) => {
        setExpandedRows(prev => ({
            ...prev,
            [tagSlug]: !prev[tagSlug]
        }));
        
        if (!expandedRows[tagSlug] && (!tagCourses[tagSlug] || !tagCourses[tagSlug].courses)) {
            await fetchTagCourses(tagSlug, 1);
        }
    };

    const LimitSelector = ({ tagSlug }) => (
        <FormControl size="small" sx={{ mx: 2 }}>
            <Select
                value={coursesPerPage}
                onChange={(e) => {
                    setCoursesPerPage(e.target.value);
                    fetchTagCourses(tagSlug, pagesState[tagSlug] || 1, e.target.value);
                }}
                variant="outlined"
                size="small"
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
            </Select>
        </FormControl>
    );

    useEffect(() => {
        if (authToken && tags.length > 0) {
            tags.forEach(tag => {
                fetchTagCourses(tag.slug, 1);
            });
        }
    }, [authToken, tags]);

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Container 
            maxWidth={false} 
            sx={{ 
                mt: 4,
                mx: 2 // This adds margin left and right
            }}
        >
            <Typography variant="h4" align="center" sx={{ mb: 4 }}>
                Tag Management
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography>Staging</Typography>
                    <Switch
                        checked={isProduction}
                        onChange={(e) => setIsProduction(e.target.checked)}
                        color="primary"
                    />
                    <Typography>Production</Typography>
                </Box>
                
                <Button 
                    variant="contained" 
                    onClick={() => setOpenDialog(true)}
                    startIcon={<LabelIcon />}
                >
                    Create New Tag
                </Button>
            </Box>

            {isProduction && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact all live courses.
                </Alert>
            )}

            {sortedTags.map((tag) => (
                <TagSection key={tag.slug}>
                    <TableContainer component={Paper}>
                        <Table>
                        <TableHead>
                            <StyledTableRow>
                                <StyledHeaderCell>Tag Name</StyledHeaderCell>
                                <StyledHeaderCell>Slug</StyledHeaderCell>
                                <StyledHeaderCell>Associated Courses</StyledHeaderCell>
                                <StyledHeaderCell>Actions</StyledHeaderCell>
                            </StyledTableRow>
                        </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                <FixedWidthCell >
                                    <Chip
                                        icon={<LabelIcon />}
                                        label={tag.name}
                                        color="primary"
                                        variant="outlined"
                                    />
                                    </FixedWidthCell>
                                    <FixedWidthCell>{tag.slug}</FixedWidthCell>
                                    <FixedWidthCell>
                                        {loadingTags[tag.slug] ? (
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <CircularProgress size={16} sx={{ mr: 1 }} />
                                                <Typography variant="body2" color="text.secondary">
                                                    Loading...
                                                </Typography>
                                            </Box>
                                        ) : (
                                            tagCourses[tag.slug]?.pagination?.totalItems || 0
                                        )}
                                    </FixedWidthCell>
                                    <FixedWidthCell>
                                        <IconButton
                                            onClick={() => handleExpandClick(tag.slug)}
                                            aria-expanded={expandedRows[tag.slug]}
                                            sx={{ 
                                                display: 'flex', 
                                                alignItems: 'center',
                                                '& .MuiTypography-root': {
                                                    ml: 1
                                                }
                                            }}
                                        >
                                            {expandedRows[tag.slug] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            <Typography variant="body2">
                                                {expandedRows[tag.slug] ? 'Hide courses' : 'Show courses'}
                                            </Typography>
                                        </IconButton>
                                    </FixedWidthCell>
                                </StyledTableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                        <Collapse in={expandedRows[tag.slug]} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Courses
                                                </Typography>
                                                {isLoadingCourses ? (
                                                    <CircularProgress size={24} />
                                                ) : tagCourses[tag.slug]?.courses ? (
                                                    <>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell width="50px">#</TableCell>
                                                                    <TableCell>Name</TableCell>
                                                                    <TableCell>Education Provider</TableCell>
                                                                    <TableCell>Category</TableCell>
                                                                    <TableCell>Links</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {tagCourses[tag.slug].courses.map((course, index) => {
                                                                    const rowNumber = ((pagesState[tag.slug] || 1) - 1) * coursesPerPage + index + 1;
                                                                    const { hubUrl, marketplaceUrl } = getCourseUrl(course._id);
                                                                    return (
                                                                        <TableRow key={course._id}>
                                                                            <TableCell>{rowNumber}</TableCell>
                                                                            <TableCell component="th" scope="row">
                                                                                {course.name}
                                                                            </TableCell>
                                                                            <TableCell>{course.educationProvider.name}</TableCell>
                                                                            <TableCell>
                                                                                {course.categories.map(cat => (
                                                                                    <Chip
                                                                                    key={cat._id}
                                                                                    label={cat.name}
                                                                                    size="small"
                                                                                    sx={{ mr: 0.5, mb: 0.5 }}
                                                                                    />
                                                                                ))}
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <Stack direction="row" spacing={1}>
                                                                                    <Link 
                                                                                        href={hubUrl}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        Hub <OpenInNewIcon sx={{ fontSize: 14 }} />
                                                                                    </Link>
                                                                                    <Link 
                                                                                        href={marketplaceUrl}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                    >
                                                                                        Marketplace <OpenInNewIcon sx={{ fontSize: 14 }} />
                                                                                    </Link>
                                                                                </Stack>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                        
                                                        {tagCourses[tag.slug]?.pagination && (
                                                            <Box sx={{ 
                                                                display: 'flex', 
                                                                justifyContent: 'flex-end', 
                                                                alignItems: 'center',
                                                                mt: 2,
                                                                gap: 1 
                                                            }}>
                                                                <Typography variant="body2" color="text.secondary">
                                                                    Rows per page:
                                                                </Typography>
                                                                <LimitSelector tagSlug={tag.slug} />
                                                                <Pagination
                                                                    count={tagCourses[tag.slug].pagination.totalPages}
                                                                    page={pagesState[tag.slug] || 1}
                                                                    onChange={(event, newPage) => handlePageChange(tag.slug, newPage)}
                                                                    color="primary"
                                                                    size="small"
                                                                />
                                                                <Typography variant="caption" sx={{ ml: 2 }}>
                                                                    {`Total: ${tagCourses[tag.slug].pagination.totalItems} courses`}
                                                                </Typography>
                                                            </Box>
                                                        )}
                                                    </>
                                                ) : (
                                                    <Typography variant="body2" color="text.secondary">
                                                        No courses found for this tag
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TagSection>
            ))}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Create New Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Tag Name"
                        fullWidth
                        variant="outlined"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        disabled={isCreatingTag}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleCreateTag} 
                        disabled={isCreatingTag || !newTagName.trim()}
                    >
                        {isCreatingTag ? (
                            <>
                                Creating... <CircularProgress size={20} sx={{ ml: 1 }} />
                            </>
                        ) : (
                            'Create Tag'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default MydraTagsPage;
