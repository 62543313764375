import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { UserDocumentStatusChip } from './UserDocumentStatusChip';

export function UpdateApplicationStatusConfirmationDialog({
    open,
    documentsToNotifyUser,
    onClose,
}) {
    const isMutating = !!useIsMutating({
        mutationKey: ['application'],
    });

    return (
        <Dialog open={open} onClose={() => onClose(null)}>
            <DialogTitle>
                Are you sure you want to send back this application to the user?
            </DialogTitle>
            <DialogContent>
                <Typography>
                    We'll notify the user about the following documents:
                </Typography>
                <ul>
                    {documentsToNotifyUser.map((document) => (
                        <li key={document._id}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {document.type}
                            </span>{' '}
                            -
                            <UserDocumentStatusChip
                                status={document.status}
                                showIcon
                            />
                        </li>
                    ))}
                </ul>
                If you still need to update other documents status's, please
                close this modal and do so before sending back the application
                to the user.
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={
                        isMutating ? <CircularProgress size={16} /> : null
                    }
                    onClick={() => onClose(true)}
                    disabled={isMutating}
                >
                    {isMutating
                        ? 'Updating application...'
                        : 'Send back application to user'}
                </Button>
                <Button
                    disabled={isMutating}
                    onClick={() => onClose(false)}
                    autoFocus
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
