export const USER_DOCUMENT_STATUS = {
    Created: 'created',
    Extracting: 'extracting',
    ExtractionOk: 'extraction_ok',
    ExtractionFailed: 'extraction_failed',
    Rejected: 'rejected',
    Validated: 'validated',
    ReviewRequired: 'review_required',
};

export const USER_DOCUMENT_TYPES = {
    Identification: 'identification',
    ProofOfAddress: 'proof_of_address',
    Payslip: 'payslip',
    Asnef: 'asnef',
    BankStatement: 'bank_statement',
    PortugueseCreditResponsibilityMap: 'portuguese_credit_responsibility_map',
};

export const REVIEWABLE_DOCUMENT_TYPES = [
    USER_DOCUMENT_TYPES.Identification,
    USER_DOCUMENT_TYPES.ProofOfAddress,
    USER_DOCUMENT_TYPES.Payslip,
    //USER_DOCUMENT_TYPES.Asnef, // Asnef documents are created based on the identification document
    USER_DOCUMENT_TYPES.BankStatement,
    USER_DOCUMENT_TYPES.PortugueseCreditResponsibilityMap,
];
