import React from 'react';
import FundingCalculator from '../../components/FundingCalculator/FundingCalculator';
import { Box, Typography, Paper } from '@mui/material';

const CompanyProposalsPage = () => {
  return (
    <Box sx={{ maxWidth: '1200px', margin: '0 auto', p: 3 }}>
      <Typography 
        variant="h4" 
        component="h1" 
        sx={{ 
          mb: 4,
          textAlign: 'center',
          color: 'primary.main',
          fontWeight: 'medium'
        }}
      >
        Company Proposals & Funding Calculator
      </Typography>
      <Paper 
        elevation={3} 
        sx={{ 
          p: 4,  // More padding
          bgcolor: 'background.paper',
          borderRadius: 2
        }}
      >
        <FundingCalculator />
      </Paper>
    </Box>
  );
};

export default CompanyProposalsPage; 