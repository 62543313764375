import { useQueries } from '@tanstack/react-query';
import { useFetch } from '../useFetch';

export function useMydraDocuments(documentIds, queryOptions) {
    const fetch = useFetch();

    return useQueries({
        queries: documentIds.map((id) => ({
            queryKey: ['documents', id],
            queryFn: () => fetch(`/api/mydra/documents/${id}`),
            staleTime: 10,
            ...queryOptions,
        })),
    });
}
