import React, { useEffect, useState, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {
    Container,
    Typography,
    Switch,
    Box,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Alert,
    Tooltip,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Chip,
    IconButton,
    Collapse,
    styled,
    Snackbar,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import CancelIcon from '@mui/icons-material/Cancel';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import LinkIcon from '@mui/icons-material/Link';

const OrderRow = ({ order, formatCurrency, index, pagination }) => {
    const [open, setOpen] = useState(false);
    const [copyFeedback, setCopyFeedback] = useState(false);
    const [copiedText, setCopiedText] = useState('');

    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        
        // Absolute date format
        const formattedDate = date.toLocaleDateString('en-US', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        });

        // Relative time format
        const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);
        
        let relativeTime;
        if (diffInSeconds < 60) {
            relativeTime = rtf.format(-diffInSeconds, 'second');
        } else if (diffInSeconds < 3600) {
            relativeTime = rtf.format(-Math.floor(diffInSeconds / 60), 'minute');
        } else if (diffInSeconds < 86400) {
            relativeTime = rtf.format(-Math.floor(diffInSeconds / 3600), 'hour');
        } else if (diffInSeconds < 2592000) {
            relativeTime = rtf.format(-Math.floor(diffInSeconds / 86400), 'day');
        } else if (diffInSeconds < 31536000) {
            relativeTime = rtf.format(-Math.floor(diffInSeconds / 2592000), 'month');
        } else {
            relativeTime = rtf.format(-Math.floor(diffInSeconds / 31536000), 'year');
        }

        return (
            <Box>
                <Typography variant="body2">{formattedDate}</Typography>
                <Typography variant="caption" color="textSecondary">
                    {relativeTime}
                </Typography>
            </Box>
        );
    };
    
    const handleRowClick = (event) => {
        // Prevent opening the drawer if clicking on a link
        if (event.target.tagName.toLowerCase() !== 'a') {
            setOpen(!open);
        }
    };

    const handleCopyId = (id, type) => {
        navigator.clipboard.writeText(id);
        setCopiedText(`${type} ID copied to clipboard`);
        setCopyFeedback(true);
    };

    const renderCourseDetails = (course) => {
        if (!course) return 'N/A';
        const courseUrl = `https://marketplace.mydra.io/courses/${course._id}`;
        const selectedCohort = course.cohorts?.find(cohort => cohort._id === order.item?.cohort);
        const contractChoices = order.application?.applicationData?.contractChoices;
        
        return (
            <Box>
                <div>
                    <a href={courseUrl} target="_blank" rel="noopener noreferrer">{course.name || 'N/A'}</a>
                </div>
                <div>{course.educationProvider?.name || 'N/A'}</div>

                {selectedCohort && (
                    <Box sx={{ mt: 1, pt: 1, borderTop: '1px solid #eee' }}>
                        <Typography variant="body2" color="textSecondary">Cohort Details:</Typography>
                        <Typography variant="body2">
                            Location: {contractChoices?.courseLocation || selectedCohort.location}
                        </Typography>
                        <Typography variant="body2">
                            Start Date: {formatDate(contractChoices?.courseStartDate || selectedCohort.startDate)}
                        </Typography>
                        <Typography variant="body2">
                            End Date: {formatDate(selectedCohort.endDate)}
                        </Typography>
                        <Typography variant="body2">
                            Class Type: {selectedCohort.classType}
                        </Typography>
                        <Typography variant="body2">
                            Format: {selectedCohort.format}
                        </Typography>
                        <Typography variant="body2">
                            Duration: {selectedCohort.duration?.quantity} {selectedCohort.duration?.type}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };

    const renderPaymentDetails = () => {
        const totalAmount = order.totalAmount || 0;
        const learningBudgetAmount = order.learningBudgetAmount?.amount || 0;
        const currency = order.currency || 'EUR';
        const financialProduct = order.financialProduct;
        const contractChoices = order.application?.applicationData?.contractChoices;
    
        const calculateUserPayment = () => {
            return Math.max(0, totalAmount - learningBudgetAmount);
        };
    
        const calculateMonthlyPayment = (installments) => {
            if (!installments || installments <= 0) return 0;
            return calculateUserPayment() / installments;
        };

        
        return (
            <Box>
                <div>Total: {formatCurrency(totalAmount, currency)}</div>
                {learningBudgetAmount > 0 && (
                    <div>Learning Budget: {formatCurrency(learningBudgetAmount, currency)}</div>
                )}
                
                {order.paymentTerm === 'UPFRONT' && (
                    <div>User Payment: {formatCurrency(calculateUserPayment(), currency)}</div>
                )}
                
                {order.paymentTerm === 'FIXPAY' && (
                    <div>
                        FixPay Payments: {contractChoices?.fixedNumberOfInstalments || 'N/A'} x{' '}
                        {formatCurrency(
                            calculateMonthlyPayment(contractChoices?.fixedNumberOfInstalments),
                            currency
                        )}
                    </div>
                )}

                {financialProduct?.financialProductData && (
                    <Box sx={{ mt: 1, pt: 1, borderTop: '1px solid #eee' }}>
                        <Typography variant="body2" color="textSecondary">Contract Details:</Typography>
                        <Typography variant="body2">
                            Maturity: {financialProduct.financialProductData.maturity} months
                        </Typography>
                        {contractChoices?.fixedNumberOfInstalments && (
                            <Typography variant="body2">
                                Installments: {contractChoices.fixedNumberOfInstalments}
                            </Typography>
                        )}
                        <Typography variant="body2">
                            Min Income: {formatCurrency(financialProduct.financialProductData.minThreshold, currency)}
                        </Typography>
                        <Typography variant="body2">
                            Share: {(financialProduct.financialProductData.percentage * 100).toFixed(1)}%
                        </Typography>
                        <Typography variant="body2">
                            Cap: {formatCurrency(financialProduct.financialProductData.cap, currency)}
                        </Typography>
                    </Box>
                )}
            </Box>
        );
    };


    const renderPaymentStatus = (order) => {
        const totalAmountDue = order.totalAmount || 0;
        const payments = order.payments || [];
        const totalAmountPaid = payments.reduce((sum, payment) => sum + (payment.totalAmount || 0), 0);
    
        const getStatusIcon = () => {
            if (totalAmountPaid >= totalAmountDue) {
                return <CheckCircleIcon color="success" />;
            } else if (totalAmountPaid > 0) {
                return <ScheduleIcon color="warning" />;
            } else {
                return <CancelIcon color="error" />;
            }
        };
    
        const getStatusText = () => {
            if (totalAmountPaid >= totalAmountDue) {
                return 'Fully Paid';
            } else if (totalAmountPaid > 0) {
                return 'Partially Paid';
            } else {
                return 'Not Paid';
            }
        };
    
        return (
            <Box>
                <Tooltip title={`${formatCurrency(totalAmountPaid, order.currency)} paid out of ${formatCurrency(totalAmountDue, order.currency)}`}>
                    <Box display="flex" alignItems="center">
                        {getStatusIcon()}
                        <Typography variant="body2" style={{ marginLeft: '8px' }}>
                            {getStatusText()}
                        </Typography>
                    </Box>
                </Tooltip>
                {order.paymentTerm === 'FIXPAY' && payments.length > 0 && (
                    <Box mt={1}>
                        <Typography variant="body2">Payments Received:</Typography>
                        {payments.map((payment, index) => (
                            <Box key={index} display="flex" alignItems="center" mt={0.5}>
                                <CheckCircleIcon color="success" fontSize="small" />
                                <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                    {formatCurrency(payment.totalAmount, payment.currency)}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        );
    };

    
    const renderApplicationDetails = () => {
        const app = order.application;
        
        const tooltipContent = (
            <>
                <Typography variant="body2">Application ID: {app._id}</Typography>
                <Typography variant="body2">Order ID: {order._id}</Typography>
            </>
        );
    
        const getPaymentTermChip = () => {
            const chipStyle = {
                FIXPAY: {
                    backgroundColor: '#E3F2FD', // Light blue
                    color: '#1565C0', // Darker blue for text
                },
                UPFRONT: {
                    backgroundColor: '#E8F5E9', // Light green
                    color: '#2E7D32', // Darker green for text
                }
            };
        
            return (
                <Chip 
                    label={app.applicationTerm}
                    size="small"
                    style={chipStyle[app.applicationTerm]}
                />
            );
        };
    
        
        const getToggleIcon = (value) => {
            return value ? (
                <Tooltip title="Enabled">
                    <ToggleOnIcon color="primary" />
                </Tooltip>
            ) : (
                <Tooltip title="Disabled">
                    <ToggleOffIcon color="disabled" />
                </Tooltip>
            );
        };

        const labelStyle = {
            width: '150px', // Adjust this value based on your longest label
            display: 'inline-block',
        };
    
        return (
            <Tooltip title={tooltipContent} arrow>
                <Box>
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                        <Tooltip title={`Application ID: ${app._id}`} arrow>
                            <IconButton 
                                size="small" 
                                onClick={() => handleCopyId(app._id, 'Application')}
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`Order ID: ${order._id}`} arrow>
                            <IconButton 
                                size="small" 
                                onClick={() => handleCopyId(order._id, 'Order')}
                            >
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="body2" width='115px'>Payment Term:</Typography>
                        {getPaymentTermChip()}
                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="body2" style={labelStyle}>Learning Budget:</Typography>
                        {getToggleIcon(app?.learningBudget)}

                    </Box>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="body2" style={labelStyle}>Flexible Remuneration:</Typography>
                        {getToggleIcon(app?.flexibleRemuneration)}
                    </Box>
                </Box>
            </Tooltip>
        );
    };

    const renderPayments = () => {
        if (!order.payments || order.payments.length === 0) {
            return <Typography variant="body2">No payment information available.</Typography>;
        }
        const outgoingPayments = order.payments.filter(p => p.paymentType === 'REPAYMENT_TO_EP');
        const incomingPayments = order.payments.filter(p => p.paymentType !== 'REPAYMENT_TO_EP');

        return (
            <Box>
                <Typography variant="h6" gutterBottom>Outgoing Payments</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Payer</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {outgoingPayments.map((payment, index) => (
                                <TableRow key={index}>
                                    <TableCell>{formatCurrency(payment.totalAmount, payment.currency)}</TableCell>
                                    <TableCell>
                                        <Chip 
                                            label={payment.status} 
                                            color={payment.status === 'PAID' ? 'success' : 'warning'} 
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>{payment.payer || 'N/A'}</TableCell>
                                    <TableCell>{new Date(payment.createdAt).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Incoming Payments</Typography>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Amount</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Method</TableCell>
                                <TableCell>Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incomingPayments.map((payment, index) => (
                                <TableRow key={index}>
                                    <TableCell>{formatCurrency(payment.totalAmount, payment.currency)}</TableCell>
                                    <TableCell>
                                        <Chip 
                                            label={payment.status} 
                                            color={payment.status === 'PAID' ? 'success' : 'warning'} 
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell>{payment.paymentType}</TableCell>
                                    <TableCell>{payment.paymentMethod || 'N/A'}</TableCell>
                                    <TableCell>{new Date(payment.createdAt).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Revenue</Typography>
                <Typography variant="body1">
                    Revenue: {formatCurrency(outgoingPayments[0]?.agreementFeeAmount || 0, order.currency)}
                </Typography>
            </Box>
        );
    };

    const renderStatuses = (application) => {
        if (!application || !application.statuses || application.statuses.length === 0) return 'N/A';

        return (
            <Box>
                {application.statuses.map((status, index) => (
                    <Box key={index} display="flex" alignItems="center" mb={0.5}>
                        <Typography variant="body2" mr={1} minWidth="20px">
                            {index + 1}.
                        </Typography>
                        <Chip 
                            label={status} 
                            size="small" 
                            style={{ 
                                margin: '2px',
                                backgroundColor: getStatusColor(status),
                                color: '#fff'
                            }} 
                        />
                    </Box>
                ))}
            </Box>
        );
    };

    // Helper function to get color based on status
    const getStatusColor = (status) => {
        const statusColors = {
            'APPLIED': 'rgba(52, 152, 219, 0.7)',        // Muted Blue
            'MISSING_SCORING_DATA': 'rgba(231, 76, 60, 0.7)',  // Muted Red
            'PENDING_VALIDATION': 'rgba(243, 156, 18, 0.7)',   // Muted Orange
            'APPROVED': 'rgba(46, 204, 113, 0.7)',       // Muted Green
            'PENDING_CONTRACT': 'rgba(155, 89, 182, 0.7)',     // Muted Purple
            'CONTRACT_SIGNED': 'rgba(26, 188, 156, 0.7)',      // Muted Turquoise
            'COMPLETE': 'rgba(39, 174, 96, 0.7)'         // Muted Dark Green
        };
        return statusColors[status] || '#95a5a6'; // Default to grey if status not found
    };

    // New function to render overall status
    const renderOverallStatus = (status) => {
        switch (status?.toUpperCase()) {
            case 'COMPLETED':
                return (
                    <Tooltip title="Completed">
                        <CheckCircleIcon style={{ color: 'green' }} />
                    </Tooltip>
                );
            case 'PENDING':
                return (
                    <Tooltip title="Pending">
                        <PendingIcon style={{ color: 'orange' }} />
                    </Tooltip>
                );
            case 'CANCELLED':
                return (
                    <Tooltip title="Cancelled">
                        <CancelIcon style={{ color: 'red' }} />
                    </Tooltip>
                );
            default:
                return status;
        }
    };


    const renderValidations = (application) => {
        if (!application || !application.validations || application.validations.length === 0) {
            return 'No validations';
        }
    
        const getStatusIcon = (status) => {
            switch (status) {
                case 'approved':
                    return <CheckCircleIcon color="success" fontSize="small" />;
                case 'rejected':
                    return <CancelIcon color="error" fontSize="small" />;
                case 'pending':
                default:
                    return <ScheduleIcon color="warning" fontSize="small" />;
            }
        };

        const getStatus = (reviews) => {
            if (!reviews || reviews.length === 0) return 'pending';
            const lastReview = reviews[reviews.length - 1];
            return lastReview.status || 'pending';
        };
        
        return (
            <Tooltip title={JSON.stringify(application.validations, null, 2)}>
                <Box>
                    {application.validations.map((validation, index) => (
                        <Box key={index} display="flex" alignItems="center" style={{ margin: '2px' }}>
                            {getStatusIcon(getStatus(validation.reviews))}
                            <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                {validation.name || 'Unknown'}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Tooltip>
        );
    };

    const renderUserInfo = (user) => {
        if (!user) return 'N/A';
        return (
            <Tooltip title={
                <Box>
                    <Typography variant="body2">User ID: {user._id}</Typography>
                    <Typography variant="body2">Company ID: {user.company}</Typography>
                </Box>
            } arrow>
                <Box>
                    <Typography variant="body2">{user.firstName} {user.lastName}</Typography>
                    <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                </Box>
            </Tooltip>
        );
    };



    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
            backgroundColor: '#f8f8f8',
        },
        '&:nth-of-type(odd)': {
            backgroundColor: '#ffffff',
        },
        '&:hover': {
            backgroundColor: '#eef7ff',
            cursor: 'pointer',
        },
        transition: 'background-color 0.3s ease',
    }));

    const StyledTableCell = styled(TableCell)({
        padding: '12px 16px', // Adjust padding for better spacing
    });

   
    return (
        <>
            <StyledTableRow onClick={handleRowClick}>
                <StyledTableCell>{(pagination.page - 1) * pagination.limit + index + 1}</StyledTableCell>
                <StyledTableCell>{renderUserInfo(order.user)}</StyledTableCell>
                <StyledTableCell>{renderApplicationDetails()}</StyledTableCell>
                <StyledTableCell>{renderCourseDetails(order.item?.course)}</StyledTableCell>
                <StyledTableCell>{renderPaymentDetails()}</StyledTableCell>
                <StyledTableCell>{renderPaymentStatus(order)}</StyledTableCell>
                <StyledTableCell>{renderValidations(order.application)}</StyledTableCell>
                <StyledTableCell>{renderStatuses(order.application)}</StyledTableCell>
                <StyledTableCell>{renderOverallStatus(order.status)}</StyledTableCell>
                <StyledTableCell>{formatDate(order.createdAt)}</StyledTableCell>
            </StyledTableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Payment Information
                            </Typography>
                            {renderPayments()}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <Snackbar
                open={copyFeedback}
                autoHideDuration={2000}
                onClose={() => setCopyFeedback(false)}
                message={copiedText}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </>
    );
};



const FilterBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
}));

// Custom styled components
const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(1),
    minWidth: 120,
    '& .MuiInputLabel-root': {
        backgroundColor: theme.palette.background.paper,
        padding: '0 4px',
    },
}));

const MydraUserOrdersPage = () => {
    const [orders, setOrders] = useState([]);
    const [authToken, setAuthToken] = useState(null);
    const [userEmail, setUserEmail] = useState(null);

    const [isProduction, setIsProduction] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        limit: 20,
        totalPages: 1,
    });
    const [filters, setFilters] = useState({
        status: '',
        paymentTerm: '',
    });

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                    setUserEmail(user.email);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    const fetchOrders = useCallback(async () => {
        console.log('Fetching orders...');
        try {
            setIsLoading(true);
            const baseParams = new URLSearchParams({
                page: pagination.page.toString(),
                limit: pagination.limit.toString(),
            });
    
            // Add include parameters as specified
            ['course', 'application', 'payments', 'financialProduct', 'user'].forEach(item => {
                baseParams.append('include', item);
            });
    
            if (filters.status) baseParams.append('status', filters.status);
            if (filters.paymentTerm) baseParams.append('paymentTerm', filters.paymentTerm);
    
            const url = `${API_BASE_URL}/mydra/orders?${baseParams}`;
            console.log('Fetching from URL:', url);
    
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
    
            const responseData = await response.json();
    
            console.log('Response data:', responseData);
        
            if (responseData && Array.isArray(responseData.data)) {
                console.log('Orders fetched:', responseData.data);
                setOrders(responseData.data);
                setPagination(prevPagination => ({
                    ...prevPagination,
                    totalPages: Math.ceil(responseData.metadata.total / pagination.limit),
                }));
            } else {
                console.error('Unexpected response structure:', responseData);
                setOrders([]);
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
            setOrders([]);
        } finally {
            setIsLoading(false);
        }
    }, [authToken, isProduction, pagination.page, pagination.limit, filters, API_BASE_URL]);


    useEffect(() => {
        if (authToken) {
            fetchOrders();
        }
    }, [authToken, isProduction, pagination.page, filters, fetchOrders]);

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const handlePageChange = (event, newPage) => {
        setPagination(prevPagination => ({
            ...prevPagination,
            page: newPage,
        }));
    };

    const handleFilterChange = (event) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [event.target.name]: event.target.value,
        }));
    };

    const formatCurrency = useCallback((amount, currency) => {
        
        
        // Convert currency symbol to code if necessary
        const currencyCode = currency === '€' ? 'EUR' : currency;

        if (amount === 0) {
            return new Intl.NumberFormat('en-US', { 
                style: 'currency', 
                currency: currencyCode,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount / 100);
        }

        
        if (!amount || !currency) {
            return 'N/A';
        }
        
        try {
            // Attempt to format with Intl.NumberFormat
            return new Intl.NumberFormat('en-US', { 
                style: 'currency', 
                currency: currencyCode,
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(amount / 100);
        } catch (error) {
            console.error('Error formatting currency:', error);
            // Fallback formatting
            return `${(amount / 100).toFixed(2)} ${currency}`;
        }
    }, []);

    // Styled components
    const StyledTableContainer = styled(TableContainer)({
        maxHeight: '70vh',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow for depth
    });

    const StyledTable = styled(Table)({
        minWidth: 650,
        backgroundColor: '#ffffff', // White background for the table
    });

    const StyledTableHead = styled(TableHead)({
        backgroundColor: '#f0f0f0', // Slightly darker grey for header
        '& .MuiTableCell-head': {
            color: '#333',
            fontWeight: 'bold',
            padding: '16px', // Increased padding for headers
        },
    });

    const StyledHeaderCell = styled(TableCell)({
        position: 'sticky',
        top: 0,
        zIndex: 2,
        backgroundColor: '#f5f5f5', // Ensure the background color is consistent when scrolling
        '&:first-of-type': {
            borderRadius: '2px 0 0 0',
        },
        '&:last-of-type': {
            borderRadius: '0 4px 0 0',
        },
    });


    return (
        <Box sx={{ mt: 4, overflowX: 'auto' }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                User Orders
            </Typography>

            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>

            <FilterBox>
                <StyledFormControl>
                    <InputLabel id="status-label">Status</InputLabel>
                    <Select
                        labelId="status-label"
                        value={filters.status}
                        onChange={handleFilterChange}
                        name="status"
                        label="Status"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="PENDING">Pending</MenuItem>
                        <MenuItem value="ACCEPTED">Accepted</MenuItem>
                        <MenuItem value="COMPLETED">Completed</MenuItem>
                        <MenuItem value="CANCELLED">Cancelled</MenuItem>
                    </Select>
                </StyledFormControl>

                <StyledFormControl>
                    <InputLabel id="payment-term-label">Payment Term</InputLabel>
                    <Select
                        labelId="payment-term-label"
                        value={filters.paymentTerm}
                        onChange={handleFilterChange}
                        name="paymentTerm"
                        label="Payment Term"
                    >
                        <MenuItem value="">All</MenuItem>
                        <MenuItem value="UPFRONT">Upfront</MenuItem>
                        <MenuItem value="FIXPAY">FixPay</MenuItem>
                        <MenuItem value="FREE">Free</MenuItem>
                    </Select>
                </StyledFormControl>
            </FilterBox>


            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact live data.
                </Typography>
            )}

            {isLoading ? (
                <Box display="flex" justifyContent="center" mt={5}>
                    <CircularProgress />
                </Box>
            ) : orders.length > 0 ? (
                <>
                    <StyledTableContainer component={Paper}>
                        <StyledTable stickyHeader>
                            <StyledTableHead>
                                <TableRow>
                                    <StyledHeaderCell style={{ minWidth: '50px' }}>#</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '100px' }}>User</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '200px' }}>Application Details</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '200px' }}>Course Details</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '250px' }}>Payment Details</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '150px' }}>Payment Status</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '120px' }}>Validations</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '100px' }}>Detailed Statuses</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '100px' }}>Overall Status</StyledHeaderCell>
                                    <StyledHeaderCell style={{ minWidth: '100px' }}>Created At</StyledHeaderCell>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {orders.map((order, index) => (
                                    <OrderRow 
                                        key={order._id} 
                                        order={order} 
                                        formatCurrency={formatCurrency}
                                        index={index}
                                        pagination={pagination}
                                    />
                                ))}
                            </TableBody>
                        </StyledTable>
                    </StyledTableContainer>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.page}
                            onChange={handlePageChange}
                            color="primary"
                            disabled={orders.length === 0}
                        />
                    </Box>
                </>
            ) : (
                <Alert severity="info" sx={{ mt: 3 }}>
                    No orders found for the current page. This might be due to a backend pagination issue. 
                    Please try returning to the first page or adjusting your filters.
                </Alert>
            )}
        </Box>
    );
};

export default MydraUserOrdersPage;