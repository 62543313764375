import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import GoogleIcon from '@mui/icons-material/Google';
import { Button, Container, Typography, Box, Paper, Alert } from '@mui/material';

const LoginPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { signIn } = useAuth();
    const from = location.state?.from || '/';

    const handleSignIn = async () => {
        try {
            await signIn();
            navigate(from, { replace: true });
        } catch (error) {
            console.error('Sign-in error:', error);
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Paper elevation={6} sx={{ marginTop: 8, padding: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h5" sx={{ lineHeight: 'normal', textAlign: 'center' }}>
                    Please Login to Access This Application
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                    Sign in with your <Box component="span" sx={{ fontWeight: 'bold' }}>@studentfinance.com</Box> email address.
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon sx={{ color: '#4285F4' }} />}
                    onClick={handleSignIn}
                    sx={{
                        mt: 3,
                        mb: 2,
                        borderColor: '#4285F4',
                        color: '#4285F4',
                        ':hover': {
                            borderColor: '#2a62c0',
                            background: '#e8f0fe',
                        },
                    }}
                >
                    Sign in with Google
                </Button>
                <Alert severity="info" sx={{ mt: 2 }}>
                    Access is restricted to StudentFinance employees.
                </Alert>
            </Paper>
        </Container>
    );
};

export default LoginPage;


