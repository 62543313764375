import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFetch } from '../useFetch';

export function useApplicationUpdate(mutationOptions) {
    const queryClient = useQueryClient();
    const fetch = useFetch();

    return useMutation({
        mutationKey: ['applicationUpdate'],
        mutationFn: ({ id, update }) =>
            fetch(`/applications/${id}`, {
                method: 'PATCH',
                body: JSON.stringify(update),
            }),
        ...mutationOptions,
        onSuccess: async (...params) => {
            await queryClient.invalidateQueries({
                queryKey: ['applications'],
            });
            return mutationOptions?.onSuccess?.(...params);
        },
    });
}
