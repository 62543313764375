import ArticleIcon from '@mui/icons-material/Article'; // Icon for Agreement Name
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for Validation Type
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

const validationTypes = ['skills', 'capacity', 'admission-process', 'none'];

const formatTerm = (term) => {
  return `${term.name} - ${new Intl.NumberFormat('es-ES', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
    .format(term.cap / 100)
    .replace(',', '.')} - ${Math.round(term.percentage * 100)}% - ${
    term.maxPayments
  } payments`;
};

const CourseValidationAgreementSection = ({
  course,
  isSectionEditable,
  onEditSection,
  onSaveSection,
  isSaving,
  authToken,
  userEmail,
  isProduction,
}) => {
  console.log('Course data:', {
    validationType: course.validationType,
    agreements: course.agreements,
    financialTerms: course.financialTerms,
  });

  const [originalValidationType, setOriginalValidationType] = useState(
    course.validationType || 'none'
  );
  const [originalAgreementName, setOriginalAgreementName] = useState(
    course.agreements[0]?.name || ''
  );

  const [editableValidationType, setEditableValidationType] = useState(
    course.validationType || 'none'
  );
  const [editableAgreementName, setEditableAgreementName] = useState(
    course.agreements[0]?.name || ''
  );

  const [agreements, setAgreements] = useState([]);
  const [financialTerms, setFinancialTerms] = useState([]);
  const [selectedFinancialTerm, setSelectedFinancialTerm] = useState(
    course.financialTerms?.[0] || ''
  );

  const providerId = course.educationProvider._id;
  const editable = isSectionEditable('validationAgreement');
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (editable) {
      const fetchAgreements = async () => {
        try {
          const response = await fetch(
            `${apiBaseUrl}/mydra/education-providers/${providerId}/agreements`,
            {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${authToken}`,
                'Content-Type': 'application/json',
                'X-Environment': isProduction ? 'Production' : 'Staging',
                'X-User-Email': userEmail,
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            // console.log('Agreements:', data);
            setAgreements(data.data || []);
            // console.log('Agreements:', agreements)
          } else {
            throw new Error('Failed to fetch agreements');
          }
        } catch (error) {
          console.error('Failed to fetch agreements', error);
        }
      };
      fetchAgreements();
    }
  }, [editable, apiBaseUrl, providerId, authToken]);

  useEffect(() => {
    const fetchFinancialTerms = async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/mydra/financial-terms`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-Environment': isProduction ? 'Production' : 'Staging',
            'X-User-Email': userEmail,
          },
        });
        if (response.ok) {
          const data = await response.json();
          console.log('Fetched financial terms:', data.data);
          setFinancialTerms(data.data || []);

          if (data.data && course.financialTerms?.[0]) {
            console.log('Looking for financial term match:', {
              courseTermId: course.financialTerms[0],
              availableTerms: data.data.map((t) => t._id),
            });

            const matchingTerm = data.data.find(
              (term) => term._id === course.financialTerms[0]
            );
            console.log('Found matching term:', matchingTerm);

            if (matchingTerm) {
              setSelectedFinancialTerm(matchingTerm._id);
            }
          }
        } else {
          throw new Error('Failed to fetch financial terms');
        }
      } catch (error) {
        console.error('Failed to fetch financial terms', error);
      }
    };
    fetchFinancialTerms();
  }, [apiBaseUrl, authToken, course.financialTerms, userEmail, isProduction]);

  useEffect(() => {
    setOriginalValidationType(course.validationType || 'none');
    setOriginalAgreementName(course.agreements[0]?.name || '');
    setEditableValidationType(course.validationType || 'none');
    setEditableAgreementName(course.agreements[0]?.name || '');
    setSelectedFinancialTerm(course.financialTerms?.[0] || '');
  }, [course]);

  const handleCancel = () => {
    setEditableValidationType(originalValidationType);
    setEditableAgreementName(originalAgreementName);
    setSelectedFinancialTerm(course.financialTerms?.[0] || '');
    onEditSection(null);
  };

  const handleSave = async () => {
    const nonTranslationFields = {
      validationType: editableValidationType === 'none' ? null : editableValidationType,
      agreementIds: editableAgreementName ? [editableAgreementName] : [],
      financialTerms: selectedFinancialTerm ? [selectedFinancialTerm] : [],
    };

    console.log('Saving with payload:', nonTranslationFields);

    const payload = {
      nonTranslationFields,
    };

    await onSaveSection('validationAgreement', payload);
  };

  console.log('Render state:', {
    originalValidationType,
    originalAgreementName,
    editableValidationType,
    editableAgreementName,
    selectedFinancialTerm,
    financialTerms: financialTerms.length,
  });

  return (
    <Card variant='outlined' sx={{ mb: 2 }}>
      <CardHeader
        action={
          editable ? (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                variant='contained'
                color='primary'
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
              <Button
                variant='outlined'
                sx={{
                  color: 'common.white',
                  borderColor: 'common.white',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    borderColor: 'common.white',
                  },
                }}
                onClick={handleCancel}
                disabled={isSaving}
              >
                Cancel
              </Button>
            </Box>
          ) : (
            <IconButton
              onClick={() => onEditSection('validationAgreement')}
              sx={{ color: 'common.white' }}
            >
              <EditIcon />
            </IconButton>
          )
        }
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
        title='Validation and Agreement'
        sx={{
          backgroundColor: 'primary.light',
          color: 'primary.contrastText',
          '.MuiCardHeader-action': { m: 'auto 0' },
          '.MuiCardHeader-title': { color: 'primary.contrastText' },
        }}
      />
      <CardContent>
        {editable ? (
          <>
            <FormControl fullWidth margin='normal'>
              <InputLabel>Validation Type</InputLabel>
              <Select
                value={editableValidationType}
                onChange={(e) => {
                  setEditableValidationType(e.target.value);
                  if (e.target.value === 'none') {
                    setEditableAgreementName('');
                  }
                }}
                label='Validation Type'
              >
                {validationTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type === 'none' ? 'No Validation' : type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <InputLabel>Agreement</InputLabel>
              <Select
                value={editableAgreementName}
                onChange={(e) => setEditableAgreementName(e.target.value)}
                label='Agreement Name'
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {agreements.map((agreement) => (
                  <MenuItem key={agreement._id} value={agreement._id}>
                    {agreement?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <InputLabel>ISA Terms</InputLabel>
              <Select
                value={selectedFinancialTerm}
                onChange={(e) => setSelectedFinancialTerm(e.target.value)}
                label='ISA Terms'
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {financialTerms.map((term) => (
                  <MenuItem key={term._id} value={term._id}>
                    {formatTerm(term)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <Typography
              variant='subtitle1'
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <CheckCircleOutlineIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Validation Type:
            </Typography>
            <Typography variant='body1' paragraph>
              {course.validationType || 'No Validation'}
            </Typography>

            <Typography
              variant='subtitle1'
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <ArticleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Agreement:
            </Typography>
            <Typography variant='body1' paragraph>
              {course.agreements?.length > 0
                ? course.agreements[0]?.name
                : 'Not available'}
            </Typography>

            <Typography
              variant='subtitle1'
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center', mt: 2 }}
            >
              <ArticleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              ISA Terms:
            </Typography>
            <Typography variant='body1' paragraph>
              {(() => {
                const matchingTerm = financialTerms.find(
                  (term) => term._id === course.financialTerms?.[0]
                );
                console.log('Matching term for display:', matchingTerm);
                return matchingTerm
                  ? formatTerm(matchingTerm)
                  : 'Not available';
              })()}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default CourseValidationAgreementSection;
