import { CheckCircle, Close } from '@mui/icons-material';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import { USER_DOCUMENT_STATUS } from './MydraUserScoringDocumentsTable.constants';
import { UserDocumentStatusChip } from './UserDocumentStatusChip';

export function UpdateDocumentsConfirmationDialog({
    open,
    documentsToUpdate,
    onClose,
}) {
    const isMutating = !!useIsMutating({
        mutationKey: ['userDocumentUpdate'],
    });

    return (
        <Dialog open={open} onClose={() => onClose(null)}>
            <DialogTitle>
                Are you sure you want to update these documents?
            </DialogTitle>
            <DialogContent>
                <ul>
                    {documentsToUpdate.map((document) => (
                        <li key={document._id}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                }}
                            >
                                {document.type}
                            </span>{' '}
                            - will be set to{' '}
                            <UserDocumentStatusChip
                                status={document.status}
                                showIcon
                            />
                        </li>
                    ))}
                </ul>

                <span style={{ marginTop: 16, display: 'block' }}>
                    <Typography variant="caption" textTransform="uppercase">
                        Instructions:
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ display: 'inline-block', marginLeft: 4 }}
                    >
                        Set a document to{' '}
                        <Typography
                            variant="caption"
                            sx={{
                                display: 'inline',
                                color: 'success.main',
                                textTransform: 'uppercase',
                            }}
                        >
                            {USER_DOCUMENT_STATUS.Validated}{' '}
                            <CheckCircle
                                color="success"
                                sx={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                }}
                            />{' '}
                        </Typography>
                        if you read their contents and you are sure they are
                        correct - this will override any failed status and the
                        user will not need to resubmit the document in the
                        future.
                    </Typography>
                    <Typography
                        variant="caption"
                        sx={{ display: 'inline-block', marginLeft: 4 }}
                    >
                        Set a document to{' '}
                        <Typography
                            variant="caption"
                            sx={{
                                display: 'inline',
                                color: 'error.main',
                                textTransform: 'uppercase',
                            }}
                        >
                            {USER_DOCUMENT_STATUS.Rejected}{' '}
                            <Close
                                color="error"
                                sx={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                }}
                            />{' '}
                        </Typography>
                        if you read their contents and you are sure they are
                        INCORRECT
                    </Typography>
                </span>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    startIcon={
                        isMutating ? <CircularProgress size={16} /> : null
                    }
                    onClick={() => onClose(true)}
                    disabled={isMutating}
                >
                    {isMutating ? 'Updating...' : 'Update documents'}
                </Button>
                <Button
                    disabled={isMutating}
                    onClick={() => onClose(false)}
                    autoFocus
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
